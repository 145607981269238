<template>
  <router-view></router-view>
</template>

<script>
export default {
  beforeCreate: () => {
    document.body.className = 'full-page';
  },
};
</script>
