<template>
  <div class="page-title">
    <h2 class="title" :class="variant">
      <slot name="title"></slot>
    </h2>
    <div class="actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "./PageTitle.scss";
</style>

<script>
export default {
  props: {
    variant: String,
  },
};
</script>
