<template>
  <div class="fluid-page profile-base-info">
    <b-container>
      <!-- Breadcrumb -->
      <breadcrumb class="mt-4"></breadcrumb>

      <!-- Page title wrapper -->
      <div class="page-title-wrapper">
        <b-link :to="{ name: 'profiles-home' }">
          <b-img :src="require('@/assets/img/icons/back.svg')" alt="Go back" />
        </b-link>
        <page-title variant="profile">
          <template #title>{{ $t('pages.settings.profiles.createProfile') }}</template>
        </page-title>
      </div>

      <!-- Info -->
      <div class="info">
        <b-alert :show="formResponse !== null" variant="danger">
          {{ formResponse }}
        </b-alert>

        <ValidationObserver ref="form">
          <b-form novalidate autocomplete="off">
            <div class="source-data">
              <!-- Profile Name -->
              <ValidationProvider rules="required" name="name" v-slot="{ valid, errors }">
                <b-form-group :label="$t('general.name')" :label-class="{ 'is-invalid': errors[0] }" :invalid-feedback="errors[0]">
                  <b-form-input v-model="baseInfo.name" :state="errors[0] ? false : valid ? true : null"></b-form-input>
                </b-form-group>
              </ValidationProvider>

              <!-- Description -->
              <ValidationProvider rules="required" name="description" v-slot="{ valid, errors }">
                <b-form-group :label="$t('general.description')" :label-class="{ 'is-invalid': errors[0] }" :invalid-feedback="errors[0]">
                  <b-form-input v-model="baseInfo.description" :state="errors[0] ? false : valid ? true : null"></b-form-input>
                </b-form-group>
              </ValidationProvider>

              <!-- Show Profile -->
              <b-form-checkbox :value="true" v-model="baseInfo.show_role" class="show-profile">
                <span>{{ $t('pages.settings.profiles.accesibleProfile') }}</span>
              </b-form-checkbox>
            </div>
          </b-form>
        </ValidationObserver>
      </div>

      <div class="call-to-action">
        <b-button @click="validateBeforeSubmit()" variant="bordered" size="lg" :disabled="sendingForm">{{ sendButtonText }}</b-button>
      </div>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
@import '../Creation';
@import './BaseInfo';
</style>

<script>
import commonSrv from '@/api/common';
import profilesSrv from '@/api/profiles';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';
import PageTitle from '@/components/PageTitle/PageTitle.vue';
import breadcrumbHandler from '@/mixins/breadcrumbHandler';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { showSuccess } from '@/components/ScreenAlert';

export default {
  mixins: [breadcrumbHandler],

  components: {
    ValidationObserver,
    ValidationProvider,
    Breadcrumb,
    PageTitle,
  },

  data() {
    return {
      loadingInfo: false,
      formResponse: null,
      sendingForm: false,
      sendButtonText: this.$t('general.create'),
      baseInfo: {
        show_role: true,
      },
    };
  },

  methods: {
    validateBeforeSubmit() {
      return commonSrv.validateForm(this.$refs.form, this.handleSubmit);
    },

    async handleSubmit() {
      this.sendButtonText = this.$t('general.sending');
      this.sendingForm = true;

      try {
        await profilesSrv.createProfile(this.baseInfo);
        this.$router.push({ name: 'profiles-home' }).catch(() => {});
        showSuccess({
          confirmLabel: '',
          content: this.$t('pages.settings.profiles.createdSuccessfully'),
        });
      } catch (error) {
        this.formResponse = error.message;
      }
      this.sendingForm = false;
      this.sendButtonText = this.$t('general.create');
    },
  },

  created() {
    this.setBreadcrumb([
      { text: this.$t('general.menu.home'), to: { name: 'visual-vault' } },
      { text: this.$t('general.settings'), to: { name: 'settings' } },
      { text: this.$t('pages.settings.profiles.title'), to: { name: 'profiles-home' } },
      { text: this.$t('pages.settings.profiles.createProfile') },
    ]);
    this.getCatalogs();
  },
};
</script>
