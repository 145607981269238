import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations('breadcrumb', {
      setBreadcrumb: 'set',
      replaceBreadcrumb: 'replace',
      emptyBreadcrumb: 'empty',
    }),
    updateBreadcrumb(find, text, name) {
      const replace = {
        text,
        to: { name },
      };

      if (!name) {
        delete replace.to;
      }

      this.replaceBreadcrumb({
        find,
        replace,
      });
    },
  },
};
