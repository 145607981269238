import PanelHome from '@/pages/Panel/Home/Panel.vue';
import Panel from '@/pages/Panel/Panel.vue';

export default {
  path: 'panel',
  name: 'panel',
  component: PanelHome,
  redirect: {
    name: 'search-panel',
  },
  children: [
    {
      path: '/',
      name: 'search-panel',
      component: Panel,
    },
  ],

};
