import Users from '@/pages/Settings/Users/Users.vue';
import UsersHome from '@/pages/Settings/Users/Home/Home.vue';
import UserCreation from '@/pages/Settings/Users/Creation/Creation.vue';
import CreateBaseInformation from '@/pages/Settings/Users/Creation/BaseInfo/BaseInfo.vue';
import UserEdition from '@/pages/Settings/Users/Edition/Edition.vue';
import EditionBaseInformation from '@/pages/Settings/Users/Edition/BaseInfo/BaseInfo.vue';

export default {
  path: 'users',
  component: Users,
  children: [
    {
      path: '/',
      name: 'users-home',
      component: UsersHome,
      meta: {
        permission: 'view-users',
      },
    },
    {
      path: 'creation',
      name: 'user-creation',
      component: UserCreation,
      redirect: {
        name: 'user-base-info-creation',
      },
      children: [
        {
          path: 'base-info',
          name: 'user-base-info-creation',
          component: CreateBaseInformation,
          meta: {
            permissions: ['view-users', 'edit-users'],
          },
        },
      ],
    },
    {
      path: ':userId/edition',
      name: 'user-edition',
      component: UserEdition,
      redirect: {
        name: 'user-base-info-edition',
      },
      children: [
        {
          path: 'base-info',
          name: 'user-base-info-edition',
          component: EditionBaseInformation,
          meta: {
            permissions: ['view-users', 'edit-users'],
          },
        },
      ],
    },
  ],
};
