import api from './index';

function CreatorDataPayload(info) {
  const data = { ...info };
  return {
    username: data.username,
    password: data.password,
    role: data.role,
    digital_creator_categories: data.digital_creator_categories.map(e => e.id || e),
    digital_creator_projects: data.digital_creator_projects.map(e => e.id || e),
  };
}

function getCreators(data) {
  return new Promise((resolve, reject) => {
    api({
      path: '/digital-creators/',
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function getCreator(creatorId) {
  return new Promise((resolve, reject) => {
    api({
      path: `/digital-creators/${creatorId}/`,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function updateCreatorStatus(creatorId, status) {
  const data = {
    status,
  };
  return new Promise((resolve, reject) => {
    api({
      method: 'PUT',
      path: `/digital-creators/${creatorId}/status/`,
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function updateCreator(creatorId, info) {
  const data = CreatorDataPayload(info);

  return new Promise((resolve, reject) => {
    api({
      method: 'PUT',
      path: `/digital-creators/${creatorId}/`,
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function deleteCreator(creatorId) {
  return new Promise((resolve, reject) => {
    api({
      method: 'DELETE',
      path: `/digital-creators/${creatorId}/`,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function createCreator(info) {
  const data = CreatorDataPayload(info);

  return new Promise((resolve, reject) => {
    api({
      method: 'POST',
      path: '/digital-creators/',
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function getCreatorsRoles(customerId, data) {
  return new Promise((resolve, reject) => {
    api({
      path: '/digital-creators/roles/',
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

export default {
  getCreators,
  getCreator,
  updateCreator,
  deleteCreator,
  createCreator,
  getCreatorsRoles,
  updateCreatorStatus,
};
