import Dashboard from '@/pages/Dashboard/Dashboard.vue';
import Home from '@/pages/Dashboard/Home/Home.vue';
import Search from '@/pages/Search/Search.vue';

export default {
  path: 'dashboard',
  name: 'dashboard',
  component: Dashboard,
  children: [
    {
      path: '/',
      name: 'home-dashboard',
      component: Home,
      redirect: {
        name: 'search',
      },
      children: [
        {
          path: 'search',
          name: 'search',
          component: Search,
        },
      ],
    },
  ],
};
