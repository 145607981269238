import api from './index';

function TeamDataPayload(info) {
  const data = { ...info };
  return {
    name: data.name,
    description: data.description,
    projects: data.projects,
  };
}

function getTeams(data) {
  return new Promise((resolve, reject) => {
    api({
      path: '/teams/',
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function addTeamMember(teamId, creatorId) {
  const data = {
    digital_creator_id: creatorId,
  };
  return new Promise((resolve, reject) => {
    api({
      method: 'POST',
      path: `/teams/${teamId}/members/`,
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function removeTeamMember(teamId, creatorId) {
  const data = {
    digital_creator_id: creatorId,
  };
  return new Promise((resolve, reject) => {
    api({
      method: 'DELETE',
      path: `/teams/${teamId}/members/`,
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function getTeam(teamId) {
  return new Promise((resolve, reject) => {
    api({
      path: `/teams/${teamId}/`,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function getTeamMembers(teamId, data) {
  return new Promise((resolve, reject) => {
    api({
      path: `/teams/${teamId}/members/`,
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function createTeam(data) {
  return new Promise((resolve, reject) => {
    api({
      method: 'POST',
      path: '/teams/',
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function updateTeam(teamId, info) {
  const data = TeamDataPayload(info);

  return new Promise((resolve, reject) => {
    api({
      method: 'PUT',
      path: `/teams/${teamId}/`,
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

export default {
  getTeams,
  createTeam,
  updateTeam,
  getTeam,
  getTeamMembers,
  addTeamMember,
  removeTeamMember,
};
