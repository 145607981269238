const initialState = {
  locale: window.localStorage.getItem('currentLocale') || navigator.language.split('-')[0],
};

export default {
  namespaced: true,
  state: initialState,

  actions: {
    changeLocale({ commit }, info) {
      commit('setLocale', info);
    },
  },

  mutations: {
    setLocale(state, info) {
      window.localStorage.setItem('currentLocale', info);
      state.locale = info;
    },
  },

  getters: {
    locale: state => state.locale,
  },
};
