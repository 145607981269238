<template>
  <div class="common-page users-home">
    <layout-wrapper>
      <template #sidebar>
        <sidebar></sidebar>
      </template>

      <!-- Breadcrumb -->
      <breadcrumb></breadcrumb>

      <slot v-if="loadingInfo">
        <div class="getting-info">{{ $t('general.gettingInformation') }}</div>
      </slot>

      <slot v-if="!loadingInfo">
        <b-alert :show="deleteResponse !== null" variant="danger">
          {{ deleteResponse }}
        </b-alert>

        <!-- Page title -->
        <page-title variant="user">
          <template #title>{{ $t('pages.settings.users.title') }}</template>
          <template #actions>
            <b-button
              v-if="user.is_superuser || user.profile.permissions.includes('create_digital_creators')"
              class="add"
              variant="primary"
              @click="createUser"
              ><span>+ {{ $t('pages.settings.users.createUser') }}</span>
            </b-button>
          </template>
        </page-title>

        <!-- No data -->
        <div v-if="items.length === 0" class="no-data">{{ $t('general.noInformation') }}</div>

        <!-- Topics -->
        <div v-if="items.length > 0" class="topics mb-4">
          <Search class="mb-4" :instant="false" :placeholder="$t('pages.settings.users.search')" @onSubmit="onSearch" />

          <div class="common-table-container settings">
            <b-table :fields="fields" :items="items">
              <template #head(actions)="data">
                <div class="center">{{ data.label }}</div>
              </template>
              <template #cell(username)="data">
                <div class="name">
                  <strong>{{ data.item.username }}</strong>
                </div>
              </template>
              <template #cell(created_at)="data">
                <div class="created_at">
                  {{ customDateFormat(data.item.created_at) }}
                </div>
              </template>
              <template #cell(digital_creator_projects)="data">
                <div class="projects">
                  {{ getCatalogueItemNames('projects', data.item.digital_creator_projects) }}
                </div>
              </template>
              <template #cell(digital_creator_categories)="data">
                <div class="categories">
                  {{ getCatalogueItemNames('categories', data.item.digital_creator_categories) }}
                </div>
              </template>
              <template #cell(roles)="data">
                <div class="role tags">
                  <label-tag small textColor="#fff" bgColor="#555">{{ setTopic('profiles', data.item.role) }} </label-tag>
                </div>
              </template>
              <template #cell(actions)="data">
                <div class="actions">
                  <b-link v-if="user.is_superuser || user.profile.permissions.includes('create_digital_creators')" @click="onEdit(data.item.id)">
                    <i class="fa fa-pen"></i>
                    <span>{{ $t('general.edit') }}</span>
                  </b-link>
                  <b-link v-if="!data.item.is_active" @click="enableUser(data.item.id)">{{ $t('general.enable') }}</b-link>
                  <b-link v-if="data.item.is_active" @click="disableUser(data.item.id)">{{ $t('general.disable') }}</b-link>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </slot>

      <!-- Confirm modal -->
      <confirm
        :title="$t('components.confirm.title')"
        :content="$t('pages.settings.users.onDeleteMessage')"
        showCancel
        :close="confirmAction"
      ></confirm>

      <!-- Pagination wrapper -->
      <pagination
        v-show="!loadingInfo && items.length > 0"
        :page="0"
        :totalItems="totalItems"
        @updateListHandler="getUsersInfo"
      ></pagination>
    </layout-wrapper>
  </div>
</template>

<style lang="scss" scoped>
@import './Home';
</style>

<script>
import categoriesSrv from '@/api/categories';
import commonSrv from '@/api/common';
import projectsSrv from '@/api/projects';
import usersSrv from '@/api/users';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';
import LabelTag from '@/components/LabelTag/LabelTag.vue';
import LayoutWrapper from '@/components/Layout/Layout.vue';
import Confirm from '@/components/Modals/Confirm/Confirm.vue';
import PageTitle from '@/components/PageTitle/PageTitle.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import { showError, showSuccess } from '@/components/ScreenAlert';
import Search from '@/components/Search/Search';
import Sidebar from '@/components/Sidebar/Settings/Sidebar.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    LayoutWrapper,
    Sidebar,
    Breadcrumb,
    PageTitle,
    Pagination,
    Confirm,
    LabelTag,
    Search,
  },

  data() {
    return {
      loadingInfo: false,
      deleteResponse: null,
      currentCreatorId: null,
      search: '',
      totalItems: 0,
      items: [],
      fields: [
        { key: 'username', label: this.$t('general.user') },
        { key: 'created_at', label: this.$t('general.createdAt') },
        { key: 'digital_creator_projects', label: this.$t('general.projects') },
        { key: 'digital_creator_categories', label: this.$t('general.categories') },
        { key: 'roles', label: this.$t('general.menu.profiles') },
        { key: 'actions', label: this.$t('general.actions') },
      ],
      categories: [],
      projects: [],
      profiles: [],
    };
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },

  methods: {
    onSearch(search) {
      this.search = search;

      this.getUsersInfo();
    },

    async disableUser(userId) {
      try {
        await usersSrv.updateCreatorStatus(userId, false);
        this.getUsersInfo();
        showSuccess({
          confirmLabel: '',
          content: this.$t('pages.settings.users.enabledSuccessfully'),
        });
      } catch (error) {
        showError({
          confirmLabel: '',
          content: error || this.$t('general.errorMessage'),
        });
      }
    },

    async enableUser(userId) {
      try {
        await usersSrv.updateCreatorStatus(userId, true);
        this.getUsersInfo();
        showSuccess({
          confirmLabel: '',
          content: this.$t('pages.settings.users.enabledSuccessfully'),
        });
      } catch (error) {
        showError({
          confirmLabel: '',
          content: error || this.$t('general.errorMessage'),
        });
      }
    },

    setTopic(collection, id) {
      return (this[collection] || []).find((e) => e.id === id)?.description || '-';
    },

    getCatalogueItemNames(collection, currentIds) {
      return this[collection]
        .filter((item) => currentIds.includes(item.id))
        .map((item) => item.name)
        .join(', ');
    },

    customDateFormat(date) {
      return commonSrv.customDateFormat(date, 'YYYY-MM-DD');
    },

    onDeleteConfirm(creatorId) {
      this.currentCreatorId = creatorId;
      this.$bvModal.show('confirm-modal');
    },

    confirmAction(response) {
      this.$bvModal.hide('confirm-modal');
      if (response) {
        this.deleteUser(this.currentCreatorId);
      }
    },

    createUser() {
      this.$router.push({ name: 'user-creation' });
    },

    onEdit(creatorId) {
      this.$router.push({ name: 'user-edition', params: { userId: creatorId } });
    },

    async deleteUser(userId) {
      try {
        const res = await usersSrv.deleteCreator(userId);
        this.deleteResponse = res.message;
        this.getUsersInfo();
      } catch (error) {
        this.deleteResponse = error.message;
      }
    },

    async loadCatalogues() {
      try {
        const categoriesRes = await categoriesSrv.getCategories();
        const projectsRes = await projectsSrv.getProjects();
        const rolesRes = await usersSrv.getCreatorsRoles();

        this.categories = categoriesRes?.results;
        this.projects = projectsRes?.results;
        this.profiles = rolesRes.results;
      } catch (error) {}
    },

    async getUsersInfo(currentPage = 1, itemsPerPage = 10) {
      const searchData = {
        search: this.search,
        offset: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
      };

      this.loadingInfo = true;

      try {
        const { results, count } = await usersSrv.getCreators(searchData);
        this.items = results;
        this.totalItems = count;
      } catch (error) {}
      this.loadingInfo = false;
    },

    async init() {
      this.getUsersInfo();
      this.loadCatalogues();
    },
  },

  created() {
    this.init();

    this.setBreadcrumb([
      { text: 'Visual Vault', to: { name: 'visual-vault' } },
      { text: this.$t('general.settings') },
      { text: this.$t('pages.settings.users.title') },
    ]);
  },
};
</script>
