<template>
  <div class="common-page profile-permissions-edition">
    <layout-wrapper>
      <template #sidebar>
        <sidebar></sidebar>
      </template>

      <!-- Breadcrumb -->
      <breadcrumb></breadcrumb>

      <slot v-if="loadingInfo">
        <div class="getting-info">{{ $t('general.gettingInformation') }}</div>
      </slot>

      <slot v-if="!loadingInfo">
        <b-alert :show="deleteResponse !== null" variant="danger">
          {{ deleteResponse }}
        </b-alert>

        <!-- Page title -->
        <!-- Page title wrapper -->
        <div class="page-title-wrapper">
          <b-link :to="{ name: 'profiles-home' }">
            <b-img :src="require('@/assets/img/icons/back.svg')" alt="Go back" />
          </b-link>
          <page-title variant="profile">
            <template #title>{{ $t('pages.settings.profiles.permissions') }}</template>
          </page-title>
        </div>

        <!-- No data -->
        <div v-if="items.length === 0" class="no-data">{{ $t('general.noInformation') }}</div>

        <!-- Topics -->
        <div v-if="items.length > 0" class="topics mb-4">
          <Search class="mb-4" :instant="false" :placeholder="$t('pages.settings.profiles.search')" @onSubmit="onSearch" />

          <div class="common-table-container settings">
            <b-table :fields="fields" :items="items">
              <template #cell(permissionName)="data">
                <div class="name">
                  <strong>{{ data.item.name }}</strong>
                </div>
              </template>
              <template #cell(permissionDescription)="data">
                <div class="description">
                  <strong>{{ data.item.description }}</strong>
                </div>
              </template>
              <template v-slot:cell(isActive)="data">
                <b-form-checkbox
                  v-model="data.item.is_assigned"
                  @change="(e) => updateStatus(data.item, e)"
                  :disabled="!user.profile.permissions.includes('assign_permissions_to_roles') && user.profile.name !== 'superadmin'"
                >
                  <span>Active</span>
                </b-form-checkbox>
              </template>
            </b-table>
          </div>
        </div>
      </slot>

      <!-- Confirm modal -->
      <confirm
        :title="$t('components.confirm.title')"
        :content="$t('pages.settings.profiles.onDeleteMessage')"
        showCancel
        :close="confirmAction"
      ></confirm>

      <!-- Pagination wrapper -->
      <pagination
        v-show="!loadingInfo && items.length > 0"
        :page="0"
        :totalItems="totalItems"
        @updateListHandler="getProfilePermissionsInfo"
      ></pagination>
    </layout-wrapper>
  </div>
</template>

  <style lang="scss" scoped>
@import './PermissionsInfo';
</style>

<script>
import profilesSrv from '@/api/profiles';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';
import LayoutWrapper from '@/components/Layout/Layout.vue';
import Confirm from '@/components/Modals/Confirm/Confirm.vue';
import PageTitle from '@/components/PageTitle/PageTitle.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import Search from '@/components/Search/Search';
import Sidebar from '@/components/Sidebar/Settings/Sidebar.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    LayoutWrapper,
    Sidebar,
    Breadcrumb,
    PageTitle,
    Pagination,
    Confirm,
    Search,
  },

  data() {
    return {
      loadingInfo: false,
      deleteResponse: null,
      currentTeamId: null,
      search: '',
      totalItems: 0,
      items: [],
      fields: [
        { key: 'permissionName', label: this.$t('pages.settings.profiles.permissions') },
        { key: 'permissionDescription', label: this.$t('general.description') },
        { key: 'isActive', label: this.$t('pages.settings.profiles.isActive') },
      ],
    };
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },

  methods: {
    onSearch(search) {
      this.search = search;

      this.getProfilePermissionsInfo();
    },

    setTopic(collection, id) {
      return (this[collection] || []).find((e) => e.id === id)?.description || '-';
    },

    getCatalogueItemNames(collection, currentIds) {
      return this[collection]
        .filter((item) => currentIds.includes(item.id))
        .map((item) => item.name)
        .join(',');
    },

    onDeleteConfirm(profileId) {
      this.currentTeamId = profileId;
      this.$bvModal.show('confirm-modal');
    },

    confirmAction(response) {
      this.$bvModal.hide('confirm-modal');
      if (response) {
        this.deleteTeam(this.currentTeamId);
      }
    },

    async getProfilePermissionsInfo(currentPage = 1, itemsPerPage = 10) {
      const searchData = {
        search: this.search,
        offset: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
      };

      this.loadingInfo = true;

      try {
        const { results, count } = await profilesSrv.getProfilePermissions(this.$route.params.profileId, searchData);
        this.items = results;
        this.totalItems = count;
      } catch (error) {}
      this.loadingInfo = false;
    },

    async init() {
      this.getProfilePermissionsInfo();
    },

    updateStatus(assigned) {
      const value = !assigned.is_assigned;
      if (value === false) {
        profilesSrv.addPermission(this.$route.params.profileId, assigned.id).then(() => {});
      } else {
        profilesSrv.removePermission(this.$route.params.profileId, assigned.id).then(() => {});
      }
    },
  },

  created() {
    this.init();

    this.setBreadcrumb([
      { text: 'Visual Vault', to: { name: 'visual-vault' } },
      { text: this.$t('general.settings') },
      { text: this.$t('pages.settings.profiles.title') },
    ]);
  },
};
</script>
