import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import moment from 'moment';
import i18n from '../i18n';

// Utils
const message = (fieldName, validationName, params = {}, customParams = null) => {
  const cleanParams = params;
  Object.keys(cleanParams).forEach((key) => {
    if (key.startsWith('_')) delete cleanParams[key];
  });

  if (i18n.te(`forms.validations.${fieldName}.${validationName}`) && typeof i18n.t(`forms.validations.${fieldName}.${validationName}`) === 'string') {
    return i18n.t(`forms.validations.${fieldName}.${validationName}`, customParams || cleanParams);
  }

  return i18n.t(`forms.validations.${validationName}`, customParams || cleanParams);
};

function validateSequential(pattern, value) {
  let start = 0;
  let end = 3;
  let result = true;

  for (let x = end; x <= value.length; x += 1) {
    if (pattern.includes(value.slice(start, end))) {
      result = false;
    }

    start += 1;
    end += 1;
  }

  return result;
}

// Validate handlers
const oneUppperOneNumberValidate = value => value.match(/^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*#?&-_]{8,}$/g);
const nonRepeatedNumbersValidate = value => !value.match(/(\d)\1\1/g);
const ascSequentialValidate = value => validateSequential('0123456789', value);
const descSequentialValidate = value => validateSequential('9876543210', value);
const legalAgeValidate = value => moment().diff(moment(new Date(value)), 'years') >= 18;
const minFilesValidate = (value, { limit }) => value.length >= Number(limit);
const maxFilesValidate = (value, { limit }) => value.length <= Number(limit);
const haveUpperValidate = value => value.match(/^(.*[A-Z].*)$/g);
const haveDigitsValidate = value => value.match(/^(.*[0-9].*)$/g);
const min8CharsValidate = value => value.match(/^[^-\s]{8,}$/g);
const minOneSpecialCharsValidate = value => value.match(/^(.*?[#?._!@$%^&*-].*)$/g);

const validations = [
  { name: 'required' },
  { name: 'email' },
  { name: 'confirmed' },
  { name: 'min' },
  { name: 'max' },
  { name: 'min_value' },
  { name: 'max_value' },
  { name: 'oneUppperOneNumber', validate: oneUppperOneNumberValidate },
  { name: 'nonRepeatedNumbers', validate: nonRepeatedNumbersValidate },
  { name: 'ascSequential', validate: ascSequentialValidate },
  { name: 'descSequential', validate: descSequentialValidate },
  { name: 'legalAge', validate: legalAgeValidate },
  { name: 'minFiles', params: ['limit'], validate: minFilesValidate },
  { name: 'maxFiles', params: ['limit'], validate: maxFilesValidate },
  { name: 'haveUpper', validate: haveUpperValidate },
  { name: 'haveDigits', validate: haveDigitsValidate },
  { name: 'min8Chars', validate: min8CharsValidate },
  { name: 'minOneSpecialChars', validate: minOneSpecialCharsValidate },
];

// All rules
Object.keys(rules).forEach(rule => extend(rule, rules[rule]));

// Extend validations
for (let i = 0; i < validations.length; i += 1) {
  extend(validations[i].name, {
    message: (fieldName, params) => message(fieldName, validations[i].name, params),
    ...validations[i],
  });
}
