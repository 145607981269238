import authSrv from '@/api/auth';
import { showSuccess } from '@/components/ScreenAlert';
import i18n from '@/i18n';
import router from '@/router/index'; //eslint-disable-line

const initialState = {
  status: null,
  user: null,
  sessionTime: 0,
  tid: null,
};

export default {
  namespaced: true,
  state: initialState,

  actions: {
    initAuth({ commit }) {
      commit('logout');
    },

    login({ commit, dispatch }, { username, password }) {
      commit('loginRequest', { username });

      return authSrv.login({
        username,
        password,
      })
        .then(async (data) => {
          await commit('currentUser', data);
          await dispatch('setSessionTime', 86400);
          return data;
        })
        .catch((error) => {
          commit('loginFailure', error);

          throw error;
        });
    },

    logout({ commit }, withNotification = true) {
      return authSrv.logout()
        .then(() => {
          if (withNotification) {
            showSuccess({
              confirmLabel: '',
              title: i18n.t('general.information'),
              content: i18n.t('general.logoutMessage'),
            });
          }
        })
        .finally(() => {
          commit('logout');
          router.push({ name: 'login' }).catch(() => { });
        });
    },

    updateSessionInfo({ commit }, info) {
      commit('currentUser', info);
    },

    setSessionTime({ commit }, time) {
      commit('sessionTime', time);
    },

    logoutWithoutSession({ commit }, withNotification = true) {
      commit('logout');
      router.push({ name: 'login' }).catch(() => { });

      if (withNotification) {
        setTimeout(() => {
          showSuccess({
            confirmLabel: '',
            title: i18n.t('general.information'),
            content: i18n.t('general.logoutMessage'),
          });
        });
      }
    },

    refreshToken({ commit }, time) {
      return authSrv.refreshToken(time)
        .then(async (data) => {
          await commit('currentUser', data);
          await commit('setSessionTime', data);
        })
        .catch((error) => {
          commit('loginFailure', error);

          throw error;
        });
    },
  },

  mutations: {
    loginRequest(state) {
      state.status = { loggingIn: true };
      state.user = null;
    },

    sessionTime(state, time) {
      const startTime = Date.now();

      if (state.tid !== null) {
        clearInterval(state.tid);
      }

      state.tid = setInterval(() => {
        const delta = Date.now() - startTime;

        state.sessionTime = time - delta / 1000;

        if (state.sessionTime <= 0) {
          clearInterval(state.tid);
          state.tid = null;
        }
      }, 200);
    },

    loginFailure(state, err) {
      state.status = err;
      state.user = null;
      state.sessionTime = 0;
      state.tid = null;
    },

    logout(state) {
      state.status = null;
      state.user = null;
      state.sessionTime = 0;
      state.tid = null;
    },

    currentUser(state, info) {
      if (info) {
        state.status = { loggedIn: true };
        state.user = {
          ...state.user,
          ...info,
        };
      } else {
        state.status = { loggedIn: false };
        state.user = info;
      }
    },
  },

  getters: {
    status: state => state.status,
    user: state => state.user,
    sessionTime: state => state.sessionTime,
    tid: state => state.tid,
  },
};
