import moment from 'moment';

function setDateFormat(date) {
  return moment(date).format();
}

function customDateFormat(date, format) {
  return moment(date).format(format);
}

function resetDateFormat(date, prevFormat, format) {
  return moment(date, prevFormat).format(format);
}

function getYearsSinceDate(date) {
  return moment().diff(date, 'years', false);
}

function getDays() {
  return moment.weekdaysShort();
}

function getMonths() {
  return moment.months().map(item => item.charAt(0).toUpperCase() + item.substr(1).toLowerCase());
}

function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function duplicateArr(arr, times) {
  return Array(times).fill([...arr]).reduce((a, b) => a.concat(b));
}

function formatRange(info, startName = 'startDate', endName = 'endDate') {
  const obj = {};

  if (info.dates && Object.keys(info.dates).length > 0 && info.dates.startDate && info.dates.endDate) {
    const { startDate, endDate } = info.dates;

    obj[startName] = customDateFormat(startDate, 'YYYY-MM-DD');
    obj[endName] = customDateFormat(endDate, 'YYYY-MM-DD');
  }

  return obj;
}

function goToFirstError() {
  setTimeout(() => {
    document.querySelectorAll('form .is-invalid')[0].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  });
}

async function validateForm(form, cb) {
  const isValid = await form.validate();
  if (isValid && cb) {
    cb();
  } else {
    goToFirstError();
  }
}

function getSingleValue(array = [], prop, value, responseField) {
  const filteredInfo = array.filter(e => e[prop] === value);

  return filteredInfo.length > 0 ? filteredInfo[0][responseField] : '-';
}

function getMultipleValues(array = [], prop, values) {
  const filteredInfo = array.filter(e => values.includes(e[prop]));

  return filteredInfo.length > 0 ? filteredInfo : null;
}

function formatMultiselect(info, label = 'Todas') {
  return {
    options: [{
      label,
      info: [...info],
    }],
  };
}

function downloadFile(url, name, extension) {
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const objectUrl = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = objectUrl;
      a.download = `${name}.${extension}`;

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(objectUrl);
      document.body.removeChild(a);
    });
// .catch(error => {
//   error => reject(error.response.data)
// });
}

function URLify(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  return text.replace(urlRegex, url => `<a href="${url}" target="_blank">${url}</a>`);
}

async function urlToFile(url) {
  const response = await fetch(url);
  const data = await response.blob();
  const filename = url.substring(url.lastIndexOf('/') + 1);
  const mimeType = data.type;
  return new File([data], filename, { type: mimeType });
}

function getArrayValuesString(arr, prop, separator = ',') {
  if (arr && arr.length > 0) {
    return arr.map(e => e[prop] || e).join(separator);
  }

  return null;
}

export default {
  setDateFormat,
  customDateFormat,
  resetDateFormat,
  getYearsSinceDate,
  getDays,
  getMonths,
  cloneObject,
  duplicateArr,
  formatRange,
  goToFirstError,
  validateForm,
  getSingleValue,
  getMultipleValues,
  formatMultiselect,
  downloadFile,
  URLify,
  getArrayValuesString,
  urlToFile,
};
