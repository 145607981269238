<template>
  <div class="common-page categories-home">
    <layout-wrapper>
      <template #sidebar>
        <sidebar></sidebar>
      </template>

      <!-- Breadcrumb -->
      <breadcrumb></breadcrumb>

      <slot v-if="loadingInfo">
        <div class="getting-info">{{ $t('general.gettingInformation') }}</div>
      </slot>

      <slot v-if="!loadingInfo">
        <b-alert :show="deleteResponse !== null" variant="danger">
          {{ deleteResponse }}
        </b-alert>

        <!-- Page title -->
        <page-title variant="category">
          <template #title>{{ $t('pages.settings.categories.title') }}</template>
          <template #actions>
            <b-button
              v-if="user.is_superuser || user.profile.permissions.includes('create_categories')"
              class="add"
              variant="primary"
              @click="createCategory"
              ><span>+ {{ $t('pages.settings.categories.createCategory') }}</span>
            </b-button>
          </template>
        </page-title>

        <!-- No data -->
        <div v-if="items.length === 0" class="no-data">{{ $t('general.noInformation') }}</div>

        <!-- Topics -->
        <div v-if="items.length > 0" class="topics mb-4">
          <Search class="mb-4" :instant="false" :placeholder="$t('pages.settings.categories.search')" @onSubmit="onSearch" />

          <div class="common-table-container settings">
            <b-table :fields="fields" :items="items">
              <template #head(actions)="data">
                <div class="center">{{ data.label }}</div>
              </template>
              <template #cell(categoryName)="data">
                <div class="name">
                  <strong>{{ data.item.name }}</strong>
                </div>
              </template>
              <template #cell(categoryDescription)="data">
                <div class="description">
                  <strong>{{ data.item.description }}</strong>
                </div>
              </template>
              <template #cell(categoryExtensions)="data">
                <div class="extension tags">
                  <span v-for="(extension, index) in data.item.extensions" :key="index">
                    <label-tag small textColor="#fff" bgColor="#555">
                      {{ extension.name }}
                    </label-tag>
                    <span v-if="index < data.item.extensions.length - 1">&nbsp;</span>
                  </span>
                </div>
              </template>
              <template #cell(actions)="data">
                <div class="actions">
                  <b-link v-if="user.is_superuser || user.profile.permissions.includes('edit_categories')" @click="onEdit(data.item.id)">
                    <i class="fa fa-pen"></i>
                    <span>{{ $t('general.edit') }}</span>
                  </b-link>
                </div>
              </template>
              <template #cell(order)="data">
                <div>
                  <b-form-input type="number"
                    v-model="data.item.order"
                    :min="1" :max="totalItems"
                    @change="handleChange(data.item)"
                    @keyup.enter="handleEnter(data.item)"
                    class="p-1 custom-input">
                  </b-form-input>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </slot>

      <!-- Confirm modal -->
      <confirm
        :title="$t('components.confirm.title')"
        :content="$t('pages.settings.categories.onDeleteMessage')"
        showCancel
        :close="confirmAction"
      ></confirm>

      <!-- Pagination wrapper -->
      <pagination
        v-show="!loadingInfo && items.length > 0"
        :page="0"
        :totalItems="totalItems"
        @updateListHandler="getCategoriesInfo"
      ></pagination>
    </layout-wrapper>
  </div>
</template>

  <style lang="scss" scoped>
@import './Home';
</style>

<script>
import categoriesSrv from '@/api/categories';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';
import LabelTag from '@/components/LabelTag/LabelTag.vue';
import LayoutWrapper from '@/components/Layout/Layout.vue';
import Confirm from '@/components/Modals/Confirm/Confirm.vue';
import PageTitle from '@/components/PageTitle/PageTitle.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import Search from '@/components/Search/Search';
import Sidebar from '@/components/Sidebar/Settings/Sidebar.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    LayoutWrapper,
    Sidebar,
    Breadcrumb,
    PageTitle,
    Pagination,
    Confirm,
    LabelTag,
    Search,
  },

  data() {
    return {
      loadingInfo: false,
      deleteResponse: null,
      currentCategoryId: null,
      search: '',
      totalItems: 0,
      items: [],
      fields: [
        { key: 'categoryName', label: this.$t('general.category') },
        { key: 'categoryDescription', label: this.$t('general.description') },
        { key: 'categoryExtensions', label: this.$t('general.extensions') },
        { key: 'actions', label: this.$t('general.actions') },
        { key: 'order', label: this.$t('general.order') },
      ],
      currentPage: 1,
    };
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },

  methods: {
    onSearch(search) {
      this.search = search;

      this.getCategoriesInfo();
    },

    setTopic(collection, id) {
      return (this[collection] || []).find((e) => e.id === id)?.description || '-';
    },

    getCatalogueItemNames(collection, currentIds) {
      return this[collection]
        .filter((item) => currentIds.includes(item.id))
        .map((item) => item.name)
        .join(',');
    },

    onDeleteConfirm(categoryId) {
      this.currentCategoryId = categoryId;
      this.$bvModal.show('confirm-modal');
    },

    confirmAction(response) {
      this.$bvModal.hide('confirm-modal');
      if (response) {
        this.deleteCategory(this.currentCategoryId);
      }
    },

    createCategory() {
      this.$router.push({ name: 'category-creation' });
    },

    onEdit(categoryId) {
      this.$router.push({ name: 'category-edition', params: { categoryId } });
    },

    async deleteCategory(categoryId) {
      try {
        const res = await categoriesSrv.deleteCategory(categoryId);
        this.deleteResponse = res.message;
        this.getCategoriesInfo();
      } catch (error) {
        this.deleteResponse = error.message;
      }
    },

    async changeOrder(category) {
      const requestData = {
        category_id: category.id,
        new_order: category.order,
      };
      try {
        await categoriesSrv.swapCategoriesOrder(requestData);
        await this.getCategoriesInfo(this.currentPage);
      } catch (error) {
      }
    },

    handleChange(item) {
      this.changeOrder(item);
    },
    handleEnter(item) {
      this.changeOrder(item);
    },

    async getCategoriesInfo(currentPage = 1, itemsPerPage = 10) {
      this.currentPage = currentPage;
      const searchData = {
        search: this.search,
        offset: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
      };

      this.loadingInfo = true;

      try {
        const { results, count } = await categoriesSrv.getCategories(searchData);
        this.items = results;
        this.totalItems = count;
      } catch (error) {}
      this.loadingInfo = false;
    },

    async init() {
      this.getCategoriesInfo();
    },
  },

  created() {
    this.init();

    this.setBreadcrumb([
      { text: 'Visual Vault', to: { name: 'visual-vault' } },
      { text: this.$t('general.settings') },
      { text: this.$t('pages.settings.categories.title') },
    ]);
  },
};
</script>
