<template>
  <div class="admin">
    <b-container>

      <!-- Section content -->
      <div class="section-content">
        <breadcrumb></breadcrumb>
        <router-view></router-view>
      </div>

    </b-container>
  </div>
</template>

<style lang="scss" scoped>
@import "./Admin.scss";
</style>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    Breadcrumb: () => import('@/components/Breadcrumb/Breadcrumb.vue'),
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      sessionTime: 'auth/sessionTime',
    }),
  },

  created() {
    this.getCommonCatalogs();
    if (!this.user || !this.user.active) {
      this.$store.dispatch('auth/logoutWithoutSession');
    }
  },

  methods: {
    getCommonCatalogs() {
      return Promise.all([
        this.$store.dispatch('common/getCategories'),
        this.$store.dispatch('common/getProjects'),
      ]);
    },

    refreshToken() {
      this.$store.dispatch('auth/refreshToken', this.user.access_token)
        .then(() => {
          this.getCommonCatalogs();
        })
        .catch(() => {
          this.$store.dispatch('auth/logoutWithoutSession');
        });
    },
  },

  watch: {
    '$i18n.locale': {
      handler() {
        this.getCommonCatalogs();
      },
    },

    $route: {
      immediate: true,
      handler(newVal) {
        if (!newVal.redirectedFrom && this.user?.active) {
          // this.refreshToken();
        }
      },
    },

    sessionTime(seconds) {
      if (seconds <= 0) {
        this.$store.dispatch('auth/logoutWithoutSession');
      }
    },
  },
};
</script>
