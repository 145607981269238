import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import i18n from './i18n';
import auth from './auth'; // eslint-disable-line
import catalogs from './catalogs';
import breadcrumb from './breadcrumb';
import common from './common';


Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.localStorage,
  })],
  modules: {
    i18n,
    auth,
    catalogs,
    breadcrumb,
    common,
  },
});
