<template>
  <div class="search-form">
    <b-form @submit.prevent="submitSearch()">
      <b-row>
        <b-col :cols="instant ? 2 : 1">
          <b-button class="reset" block variant="light" @click="reset"
            ><i :style="{ color: resetColor }" class="fas fa-eraser fa-lg"></i
          ></b-button>
        </b-col>
        <b-col :cols="instant ? 11 : 9">
          <b-form-group class="search mb-0" :class="variant">
            <b-form-input
              :class="variant"
              :placeholder="placeholder"
              v-model="search"
              trim
              @update="instant ? submitSearch() : false"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col v-if="!instant" cols="2">
          <b-button block type="submit" variant="primary" :size="variant">Buscar</b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<style lang="scss">
@import './Search';
</style>

<script>
import _ from 'lodash';

export default {
  props: {
    placeholder: String,
    variant: String,
    instant: {
      type: Boolean,
      default: true,
    },
    onResetUpdate: {
      type: Boolean,
      default: true,
    },
    resetColor: {
      type: String,
      default: '#00c2ff',
    },
  },

  data() {
    return {
      search: null,
    };
  },

  created() {
    if (this.instant) this.submitSearch = _.debounce(this.submitSearch, 300);
  },

  methods: {
    submitSearch() {
      this.$emit('onSubmit', this.search);
    },

    reset() {
      this.search = '';
      if (this.instant || this.onResetUpdate) this.submitSearch();
    },
  },
};
</script>
