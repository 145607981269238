export default {
  namespaced: true,
  state: {
    currentItems: [],
  },

  mutations: {
    set(state, currentItems) {
      state.currentItems = currentItems;
    },

    replace(state, payload) {
      const index = state.currentItems.findIndex(currentItems => currentItems.text === payload.find);

      if (index >= 0) {
        state.currentItems.splice(index, 1, payload.replace);
      }
    },

    empty(state) {
      state.currentItems = [];
    },
  },

  getters: {
    currentItems: state => state.currentItems,
  },
};
