import api from './index';

function getProfiles(data) {
  return new Promise((resolve, reject) => {
    api({
      path: '/digital-creators/roles/',
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function createProfile(data) {
  return new Promise((resolve, reject) => {
    api({
      method: 'POST',
      path: '/digital-creators/roles/',
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function getProfile(roleId) {
  return new Promise((resolve, reject) => {
    api({
      path: `/digital-creators/roles/${roleId}/`,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function ProfileDataPayload(info) {
  const data = { ...info };
  return {
    name: data.name,
    description: data.description,
    show_role: data.show_role,
  };
}

function updateProfile(roleId, info) {
  const data = ProfileDataPayload(info);

  return new Promise((resolve, reject) => {
    api({
      method: 'PUT',
      path: `/digital-creators/roles/${roleId}/`,
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function addPermission(roleId, permissionId) {
  const data = {
    permission_id: permissionId,
  };
  return new Promise((resolve, reject) => {
    api({
      method: 'POST',
      path: `/digital-creators/roles/${roleId}/permissions/`,
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function removePermission(roleId, permissionId) {
  const data = {
    permission_id: permissionId,
  };
  return new Promise((resolve, reject) => {
    api({
      method: 'DELETE',
      path: `/digital-creators/roles/${roleId}/permissions/`,
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function getProfilePermissions(roleId, data) {
  return new Promise((resolve, reject) => {
    api({
      path: `/digital-creators/roles/${roleId}/permissions/`,
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

export default {
  getProfiles,
  createProfile,
  getProfile,
  updateProfile,
  addPermission,
  removePermission,
  getProfilePermissions,
};
