import Promise from 'es6-promise';
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import config from './mixins/config';
import breadcrumbHandler from './mixins/breadcrumbHandler';
import httpInterceptor from './interceptors/http';
import './validate';

// Promise finally
if (!('Promise' in window)) {
  window.Promise = Promise;
} else if (!('finally' in window.Promise.prototype)) {
  window.Promise.prototype.finally = Promise.prototype.finally;
}

Vue.config.productionTip = false;

// Libraries
Vue.use(BootstrapVue);

// Mixins
Vue.mixin(config);
Vue.mixin(breadcrumbHandler);

// HTTP Interceptor
httpInterceptor();

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
