<template>
  <div class="common-page projects-members">
    <layout-wrapper>
      <b-container v-if="!loadingInfo">
        <!-- Breadcrumb -->
        <breadcrumb></breadcrumb>

        <!-- Page title wrapper -->
        <div class="page-title-wrapper">
          <b-link :to="{ name: 'projects-home' }">
            <b-img :src="require('@/assets/img/icons/back.svg')" alt="Go back" />
          </b-link>
          <page-title>
            <template #title>{{ $t('pages.settings.projects.members.title') }}: {{ projectInfo.name }}</template>
          </page-title>
        </div>

        <div class="menu">
          <b-tabs class="naked tabs-border-bottom padding-left">
            <b-tab :title="`${$t('pages.settings.projects.members.usersList')}`" active lazy>
              <users v-if="updated" :filters="filters"></users>
            </b-tab>
            <b-tab :title="`${$t('pages.settings.projects.members.teamsList')}`" lazy>
              <teams v-if="updated" :filters="filters"></teams>
            </b-tab>
          </b-tabs>
        </div>
      </b-container>
    </layout-wrapper>
  </div>
</template>

<style lang="scss" scoped>
@import './Home';
</style>

<script>
import LayoutWrapper from '@/components/Layout/Layout.vue';
import projectsSrv from '@/api/projects';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';
import PageTitle from '@/components/PageTitle/PageTitle.vue';
import breadcrumbHandler from '@/mixins/breadcrumbHandler';
import Users from './Users/List.vue';
import Teams from './Teams/List.vue';

export default {
  mixins: [breadcrumbHandler],

  components: {
    LayoutWrapper,
    Breadcrumb,
    PageTitle,
    Users,
    Teams,
  },

  data() {
    return {
      projectId: this.$route.params.projectId,
      filters: null,
      updated: true,
      openedInvite: false,
      projectInfo: {},
      loadingInfo: true,
    };
  },

  methods: {
    async getInfo() {
      this.loadingInfo = true;
      try {
        const res = await projectsSrv.getProject(this.projectId);
        if (res.data) this.projectInfo = res.data;
        else this.projectInfo = res;
      } catch (error) {}

      this.loadingInfo = false;
    },
  },

  async created() {
    this.setBreadcrumb([
      { text: 'Home', to: { name: 'visual-vault' } },
      { text: this.$t('general.settings'), to: { name: 'settings' } },
      { text: this.$t('pages.settings.projects.title'), to: { name: 'projects-home' } },
      { text: this.$t('general.members') },
    ]);
    await this.getInfo();
  },
};
</script>
