<template>
  <b-form-group :label="`${filter ? '' : label}${required ? ' *' : ''}`" :label-for="label">
    <multiselect
      ref="refMultiselect"
      v-model="model"
      :options="compOptions"
      :required="required"
      :disabled="disabled"
      :nullable="nullable"
      :placeholder="label"
      :loading="isLoading"
      :total="totalItems"
      :filter="filter"
      :single="single"
      :icon="icon"
      :label="optionName"
      :labelEmpty="optionLabel"
      :internal-search="false"
      @search-change="onSearchChange"
      @input="onInput"
      @select-all="onSelectAll"
      @scroll-end="onScrollEnd"
    />
  </b-form-group>
</template>

<script>
import Multiselect from '@/components/Forms/Multiselect/Multiselect.vue';

export default {
  components: {
    Multiselect,
  },

  props: {
    value: { type: [Array, Object], default: () => [] },
    service: { type: Function, default: () => new Promise() },
    params: { type: Object, default: () => ({}) },
    perPage: { type: Number, default: 10 },
    label: { type: String, default: 'Opciones' },
    optionLabel: { type: String, default: 'Opción' }, // if the name comes ''
    optionName: { type: String, default: 'name' }, // name|fullname|text...
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    nullable: { type: [Boolean, String], default: false },
    filter: { type: Boolean, default: false },
    single: { type: Boolean, default: false },
    icon: { type: Boolean, default: false },
  },

  data() {
    return {
      model: this.single ? {} : [],
      startModel: [],
      delValues: [],
      options: [],
      totalItems: 0,
      currentPage: 1,
      itemsPerPage: this.perPage,
      search: '',
      isLoading: false,
    };
  },

  computed: {
    // Force to display the current selected items in case the first scroll page doesn't contain them
    compOptions() {
      // ignore on box search or service with params and 0 results
      if (this.search || (Object.keys(this.params).length && !this.totalItems)) return this.options;
      const pre = [];
      const currentItems = this.single ? [this.model] : this.model;
      currentItems.forEach((item) => {
        if (item?.id && !this.options.find((o) => o.id === item.id)) pre.push(item);
      });
      return [...pre, ...this.options];
    },

    simplifiedFilters() {
      return {
        limit: this.itemsPerPage,
        offset: this.itemsPerPage * (this.currentPage - 1),
        search: this.search,
        ...this.params,
      };
    },
  },

  watch: {
    value() {
      this.resetModel();
    },
  },

  methods: {
    init() {
      this.resetModel();
      this.resetPagination();
      this.loadOptions();
    },

    resetModel(items = null) {
      this.model = this.single ? {} : [];
      if (items || this.value) this.model = JSON.parse(JSON.stringify(items || this.value));
    },

    resetPagination(skipOptions = '') {
      if (!skipOptions) this.options = [];
      this.totalItems = 0;
      this.currentPage = 1;
      this.itemsPerPage = this.perPage;
    },

    onInput(value) {
      let delValue = this.startModel.filter(obj => !value.some(obj2 => obj.id === obj2.id));
      delValue = delValue.filter((item) => typeof item === 'object');
      this.delValues = delValue;
      this.$emit('input', this.model);
    },

    onSearchChange(search) {
      this.search = search;
      // avoid to reset options here (it causes to close the options box)
      this.resetPagination('skip options');
      this.loadOptions('clear options before display the new list');
    },

    async onSelectAll() {
      this.itemsPerPage = this.totalItems;
      await this.loadOptions('clear options');
      // check all new items
      this.$refs.refMultiselect.selectAll(false);
    },

    async onScrollEnd() {
      // prevent retrieving un-existing pages
      if (this.options.length >= this.totalItems) return;

      this.currentPage += 1;
      await this.loadOptions();
    },

    async loadOptions(clearResults = '') {
      if (typeof this.service !== 'function') return;
      this.isLoading = true;
      try {
        const { count, results, data } = await this.service(this.simplifiedFilters);
        this.totalItems = count || 0;
        if (clearResults) this.options = results || data || []; // comes from search
        else this.options = [...this.options, ...(results || data || [])];
        if (this.nullable) {
          this.totalItems += 1;
          if (this.options.length && this.options[0].id !== -1) {
            const lbl = this.nullable === true ? this.$t('components.multiselect.noOption') : this.nullable;
            this.options.unshift({ id: -1, [this.optionName]: `(${lbl})` });
          }
        }

        // id -> object
        if (this.model?.length && typeof this.model?.[0] !== 'object') {
          this.model = this.model.map((id) => this.getCatalogObject(id));
          this.startModel = this.model;
        }

        if (this.delValues.length > 0) {
          const newModel = this.options.filter(obj => !this.delValues.includes(obj));
          this.model = newModel;
        } else this.model = this.options;
      } catch (error) {}
      this.isLoading = false;
    },

    getCatalogObject(id) {
      const filter = this.options.filter((e) => e.id === id);

      return filter.length ? filter[0] : 'Error01: Out of sync id';
    },
  },

  mounted() {
    this.init();
  },
};
</script>
