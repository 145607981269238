import CreateBaseInformation from '@/pages/Settings/Profiles/Creation/BaseInfo/BaseInfo.vue';
import ProfileCreation from '@/pages/Settings/Profiles/Creation/Creation.vue';
import EditionBaseInformation from '@/pages/Settings/Profiles/Edition/BaseInfo/BaseInfo.vue';
import ProfileEdition from '@/pages/Settings/Profiles/Edition/Edition.vue';
import ProfilesHome from '@/pages/Settings/Profiles/Home/Home.vue';
import ProfilePermissions from '@/pages/Settings/Profiles/Permissions/PermissionsInfo.vue';
import Profiles from '@/pages/Settings/Profiles/Profiles.vue';

export default {
  path: 'profiles',
  component: Profiles,
  children: [
    {
      path: '/',
      name: 'profiles-home',
      component: ProfilesHome,
      meta: {
        permission: 'view-profiles',
      },
    },
    {
      path: 'creation',
      name: 'profile-creation',
      component: ProfileCreation,
      redirect: {
        name: 'profile-base-info-creation',
      },
      children: [
        {
          path: 'base-info',
          name: 'profile-base-info-creation',
          component: CreateBaseInformation,
          meta: {
            permissions: ['view-profiles', 'edit-profiles'],
          },
        },
      ],
    },
    {
      path: ':profileId/edition',
      name: 'profile-edition',
      component: ProfileEdition,
      redirect: {
        name: 'profile-base-info-edition',
      },
      children: [
        {
          path: 'base-info',
          name: 'profile-base-info-edition',
          component: EditionBaseInformation,
          meta: {
            permissions: ['view-profiles', 'edit-profiles'],
          },
        },
      ],
    },
    {
      path: ':profileId/permissions',
      name: 'profile-permissions-edition',
      component: ProfilePermissions,
      meta: {
        permissions: ['view-profiles', 'edit-profiles'],
      },
    },
  ],
};
