import categoriesSrv from '@/api/categories';
import projectsSrv from '@/api/projects';

const initialState = {
  categories: {},
  projects: {},
};

export default {
  namespaced: true,
  state: initialState,

  actions: {
    getCategories({ commit }) {
      return categoriesSrv.getCategories()
        .then((info) => {
          commit('setCategories', info);
        });
    },
    getProjects({ commit }) {
      return projectsSrv.getProjects()
        .then((info) => {
          commit('setProjects', info);
        });
    },

  },

  mutations: {
    setCategories(state, info) {
      state.categories = info.results;
    },
    setProjects(state, info) {
      state.projects = info.results;
    },
  },

  getters: {
    categories: state => state.categories,
    projects: state => state.projects,
  },
};
