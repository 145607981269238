<template>
  <div class="common-page projects-home">
    <layout-wrapper>
      <template #sidebar>
        <sidebar></sidebar>
      </template>

      <!-- Breadcrumb -->
      <breadcrumb></breadcrumb>

      <slot v-if="loadingInfo">
        <div class="getting-info">{{ $t('general.gettingInformation') }}</div>
      </slot>

      <slot v-if="!loadingInfo">
        <b-alert :show="deleteResponse !== null" variant="danger">
          {{ deleteResponse }}
        </b-alert>

        <!-- Page title -->
        <page-title variant="project">
          <template #title>{{ $t('pages.settings.projects.title') }}</template>
          <template #actions>
            <b-button class="add" variant="primary" @click="createProject"
              ><span>+ {{ $t('pages.settings.projects.createProject') }}</span></b-button
            >
          </template>
        </page-title>

        <!-- No data -->
        <div v-if="items.length === 0" class="no-data">{{ $t('general.noInformation') }}</div>

        <!-- Topics -->
        <div v-if="items.length > 0" class="topics mb-4">
          <Search class="mb-4" :instant="false" :placeholder="$t('pages.settings.projects.search')" @onSubmit="onSearch" />

          <div class="common-table-container settings">
            <b-table :fields="fields" :items="items">
              <template #head(actions)="data">
                <div class="center">{{ data.label }}</div>
              </template>
              <template #cell(name)="data">
                <div class="name">
                  <strong>{{ data.item.name }}</strong>
                </div>
              </template>
              <template #cell(actions)="data">
                <div class="actions">
                  <b-link @click="onEdit(data.item.id)">
                    <i class="fa fa-pen"></i>
                    <span>{{ $t('general.edit') }}</span>
                  </b-link>
                  <b-link @click="onMembers(data.item.id)">
                    <i class="fa fa-users"></i>
                    <span>{{ $t('general.members') }}</span>
                  </b-link>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </slot>

      <!-- Pagination wrapper -->
      <pagination
        v-show="!loadingInfo && items.length > 0"
        :page="0"
        :totalItems="totalItems"
        @updateListHandler="getProjectsInfo"
      ></pagination>
    </layout-wrapper>
  </div>
</template>

<style lang="scss" scoped>
@import './Home';
</style>

<script>
import projectsSrv from '@/api/projects';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';
import LayoutWrapper from '@/components/Layout/Layout.vue';
import PageTitle from '@/components/PageTitle/PageTitle.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import Search from '@/components/Search/Search';
import Sidebar from '@/components/Sidebar/Settings/Sidebar.vue';

export default {
  components: {
    LayoutWrapper,
    Sidebar,
    Breadcrumb,
    PageTitle,
    Pagination,
    Search,
  },

  data() {
    return {
      loadingInfo: false,
      deleteResponse: null,
      currentCreatorId: null,
      search: '',
      totalItems: 0,
      items: [],
      fields: [
        { key: 'name', label: this.$t('general.name') },
        { key: 'description', label: this.$t('general.description') },
        { key: 'actions', label: this.$t('general.actions') },
      ],
    };
  },

  methods: {
    onSearch(search) {
      this.search = search;

      this.getProjectsInfo();
    },

    setTopic(collection, id) {
      return (this[collection] || []).find((e) => e.id === id)?.description || '-';
    },

    createProject() {
      this.$router.push({ name: 'project-creation' });
    },

    onEdit(projectId) {
      this.$router.push({ name: 'project-edition', params: { projectId } });
    },

    onMembers(projectId) {
      this.$router.push({ name: 'project-members', params: { projectId } });
    },

    async getProjectsInfo(currentPage = 1, itemsPerPage = 10) {
      const searchData = {
        search: this.search,
        offset: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
      };

      this.loadingInfo = true;

      try {
        const { results, count } = await projectsSrv.getProjects(searchData);
        this.items = results;
        this.totalItems = count;
      } catch (error) {}
      this.loadingInfo = false;
    },

    async init() {
      this.getProjectsInfo();
    },
  },

  created() {
    this.init();

    this.setBreadcrumb([
      { text: 'Visual Vault Mobilender', to: { name: 'visual-vault' } },
      { text: this.$t('general.settings') },
      { text: this.$t('pages.settings.projects.title') },
    ]);
  },
};
</script>
