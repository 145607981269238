import catalogsSrv from '@/api/catalogs';

const initialState = {
  countries: [],
  genders: {},
  transactionsStatus: [],
  accountPurposes: [],
  currencies: [],
  occupations: [],
};

export default {
  namespaced: true,
  state: initialState,

  actions: {
    getCountries({ commit }) {
      return catalogsSrv.getCountries()
        .then((info) => {
          commit('setCountries', info);
        });
    },

    getGenders({ commit }) {
      return catalogsSrv.getGenders()
        .then((info) => {
          commit('setGenders', info);
        });
    },

    getTransactionsStatus({ commit }) {
      return catalogsSrv.getTransactionsStatus()
        .then((info) => {
          commit('setTransactionsStatus', info);
        });
    },

    getAccountPurposes({ commit }) {
      return catalogsSrv.getAccountPurposes()
        .then((info) => {
          commit('setAccountPurposes', info);
        });
    },

    getCurrencies({ commit }) {
      return catalogsSrv.getCurrencies()
        .then((info) => {
          commit('setCurrencies', info);
        });
    },

    getOccupations({ commit }) {
      return catalogsSrv.getOccupations()
        .then((info) => {
          commit('setOccupations', info);
        });
    },
  },

  mutations: {
    setCountries(state, info) {
      state.countries = info;
    },

    setGenders(state, info) {
      state.genders = info;
    },

    setTransactionsStatus(state, info) {
      state.transactionsStatus = info;
    },

    setAccountPurposes(state, info) {
      state.accountPurposes = info;
    },

    setCurrencies(state, info) {
      state.currencies = info;
    },

    setOccupations(state, info) {
      state.occupations = info;
    },
  },

  getters: {
    countries: state => state.countries,
    genders: state => state.genders,
    transactionsStatus: state => state.transactionsStatus,
    accountPurposes: state => state.accountPurposes,
    currencies: state => state.currencies,
    occupations: state => state.occupations,
  },
};
