import api from './index';

function CategoryDataPayload(info) {
  const data = { ...info };
  return {
    name: data.name,
    description: data.description,
    extensions: data.extensions,
  };
}

function getCategories(data) {
  return new Promise((resolve, reject) => {
    api({
      path: '/common/categories/',
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function getCategory(categoryId) {
  return new Promise((resolve, reject) => {
    api({
      path: `/common/categories/${categoryId}/`,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function createCategory(data) {
  return new Promise((resolve, reject) => {
    api({
      method: 'POST',
      path: '/common/categories/',
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function swapCategoriesOrder(data) {
  return new Promise((resolve, reject) => {
    api({
      method: 'POST',
      path: '/common/categories/swap-categories-order/',
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function updateCategory(categoryId, info) {
  const data = CategoryDataPayload(info);

  return new Promise((resolve, reject) => {
    api({
      method: 'PUT',
      path: `/common/categories/${categoryId}/`,
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

export default {
  getCategories,
  createCategory,
  getCategory,
  updateCategory,
  swapCategoriesOrder,
};
