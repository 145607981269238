import Admin from '@/pages/Admin/Admin.vue';
import Auth from '@/pages/Auth/Auth.vue';
import Login from '@/pages/Auth/Login/Login.vue';
import Vue from 'vue';
import VueRouter from 'vue-router';

import setupGuards from './guards';

// Routes
import dashboard from './dashboard';
import panel from './panel';
import settings from './settings';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Auth,
    children: [
      {
        path: '/',
        name: 'login',
        component: Login,
      },
    ],
  },
  {
    path: '/',
    name: 'visual-vault',
    component: Admin,
    meta: {
      requiresAuth: true,
    },
    redirect: {
      name: 'home-dashboard',
    },
    children: [
      { ...dashboard },
      { ...panel },
    ],
  },
  { ...settings },
  {
    path: '*',
    redirect: {
      name: 'login',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

export default setupGuards(router);
