<template>
  <div class="common-page profiles-home">
    <layout-wrapper>
      <template #sidebar>
        <sidebar></sidebar>
      </template>

      <!-- Breadcrumb -->
      <breadcrumb></breadcrumb>

      <slot v-if="loadingInfo">
        <div class="getting-info">{{ $t('general.gettingInformation') }}</div>
      </slot>

      <slot v-if="!loadingInfo">
        <b-alert :show="deleteResponse !== null" variant="danger">
          {{ deleteResponse }}
        </b-alert>

        <!-- Page title -->
        <page-title variant="profile">
          <template #title>{{ $t('pages.settings.profiles.title') }}</template>
          <template #actions>
            <b-button
              v-if="user.is_superuser || user.profile.permissions.includes('create_roles')"
              class="add"
              variant="primary"
              @click="createProfile"
              ><span>+ {{ $t('pages.settings.profiles.createProfile') }}</span>
            </b-button>
          </template>
        </page-title>

        <!-- No data -->
        <div v-if="items.length === 0" class="no-data">{{ $t('general.noInformation') }}</div>

        <!-- Topics -->
        <div v-if="items.length > 0" class="topics mb-4">
          <Search class="mb-4" :instant="false" :placeholder="$t('pages.settings.profiles.search')" @onSubmit="onSearch" />

          <div class="common-table-container settings">
            <b-table :fields="fields" :items="items">
              <template #head(actions)="data">
                <div class="center">{{ data.label }}</div>
              </template>
              <template #cell(profileName)="data">
                <div class="name">
                  <strong>{{ data.item.name }}</strong>
                </div>
              </template>
              <template #cell(profileDescription)="data">
                <div class="description">
                  <strong>{{ data.item.description }}</strong>
                </div>
              </template>
              <template #cell(actions)="data">
                <div class="actions">
                  <b-link v-if="user.is_superuser || user.profile.permissions.includes('edit_roles')" @click="onEdit(data.item.id)">
                    <i class="fa fa-pen"></i>
                    <span>{{ $t('general.edit') }}</span>
                  </b-link>
                  <b-link @click="onViewPermissions(data.item.id)">
                    <i class="fa fa-lock"></i>
                    <span>{{ $t('pages.settings.profiles.permissions') }}</span>
                  </b-link>
                  <!-- <b-link @click="onDeleteConfirm(data.item.id)">
                      <i class="fa fa-trash"></i>
                      <span>{{ $t('general.eliminate') }}</span>
                    </b-link> -->
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </slot>

      <!-- Confirm modal -->
      <confirm
        :title="$t('components.confirm.title')"
        :content="$t('pages.settings.profiles.onDeleteMessage')"
        showCancel
        :close="confirmAction"
      ></confirm>

      <!-- Pagination wrapper -->
      <pagination
        v-show="!loadingInfo && items.length > 0"
        :page="0"
        :totalItems="totalItems"
        @updateListHandler="getProfilesInfo"
      ></pagination>
    </layout-wrapper>
  </div>
</template>

  <style lang="scss" scoped>
@import './Home';
</style>

<script>
import profilesSrv from '@/api/profiles';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';
import LayoutWrapper from '@/components/Layout/Layout.vue';
import Confirm from '@/components/Modals/Confirm/Confirm.vue';
import PageTitle from '@/components/PageTitle/PageTitle.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import Search from '@/components/Search/Search';
import Sidebar from '@/components/Sidebar/Settings/Sidebar.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    LayoutWrapper,
    Sidebar,
    Breadcrumb,
    PageTitle,
    Pagination,
    Confirm,
    Search,
  },

  data() {
    return {
      loadingInfo: false,
      deleteResponse: null,
      currentProfileId: null,
      search: '',
      totalItems: 0,
      items: [],
      fields: [
        { key: 'profileName', label: this.$t('general.profile') },
        { key: 'profileDescription', label: this.$t('general.description') },
        { key: 'actions', label: this.$t('general.actions') },
      ],
    };
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },

  methods: {
    onSearch(search) {
      this.search = search;

      this.getProfilesInfo();
    },

    setTopic(collection, id) {
      return (this[collection] || []).find((e) => e.id === id)?.description || '-';
    },

    getCatalogueItemNames(collection, currentIds) {
      return this[collection]
        .filter((item) => currentIds.includes(item.id))
        .map((item) => item.name)
        .join(',');
    },

    onDeleteConfirm(profileId) {
      this.currentProfileId = profileId;
      this.$bvModal.show('confirm-modal');
    },

    confirmAction(response) {
      this.$bvModal.hide('confirm-modal');
      if (response) {
        this.deleteProfile(this.currentProfileId);
      }
    },

    createProfile() {
      this.$router.push({ name: 'profile-creation' });
    },

    onEdit(profileId) {
      this.$router.push({ name: 'profile-edition', params: { profileId } });
    },
    onViewPermissions(profileId) {
      this.$router.push({ name: 'profile-permissions-edition', params: { profileId } });
    },

    async deleteProfile(profileId) {
      try {
        const res = await profilesSrv.deleteProfile(profileId);
        this.deleteResponse = res.message;
        this.getProfilesInfo();
      } catch (error) {
        this.deleteResponse = error.message;
      }
    },

    async getProfilesInfo(currentPage = 1, itemsPerPage = 10) {
      const searchData = {
        search: this.search,
        offset: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
      };

      this.loadingInfo = true;

      try {
        const { results, count } = await profilesSrv.getProfiles(searchData);
        this.items = results;
        this.totalItems = count;
      } catch (error) {}
      this.loadingInfo = false;
    },

    async init() {
      this.getProfilesInfo();
    },
  },

  created() {
    this.init();

    this.setBreadcrumb([
      { text: 'Visual Vault', to: { name: 'visual-vault' } },
      { text: this.$t('general.settings') },
      { text: this.$t('pages.settings.profiles.title') },
    ]);
  },
};
</script>
