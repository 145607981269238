import api from './index';

function login(data) {
  return new Promise((resolve, reject) => {
    api({
      path: '/users/login/',
      method: 'POST',
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function logout() {
  return new Promise((resolve, reject) => {
    api({
      method: 'GET',
      path: '/users/logout/',
    })
      .then(res => resolve(res))
      .catch(error => reject(error.response.data));
  });
}

function refreshToken(data) {
  return new Promise((resolve, reject) => {
    api({
      path: '/users/login/refresh/',
      method: 'POST',
      data: {
        access_token: data,
      },
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

export default {
  login,
  logout,
  refreshToken,
};
