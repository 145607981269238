<template>
  <span class="label-tag" :class="{ sm: small, [variant]: variant }" :style="!this.variant ? labelStyles : null">
    <slot />
  </span>
</template>

<style lang="scss" scoped>
@import "./LabelTag";
</style>

<script>
export default {
  props: {
    small: Boolean,
    textColor: {
      type: String,
      default: '#333',
    },
    bgColor: {
      type: String,
      default: 'rgba(#CDCDCD, .4)',
    },
    variant: String,
  },

  computed: {
    labelStyles() {
      return {
        color: this.textColor,
        backgroundColor: this.bgColor,
      };
    },
  },
};
</script>
