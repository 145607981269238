<template>
  <div class="list topics mb-4">
    <Search class="mb-4" :instant="false" placeholder="Search by team's name" @onSubmit="onSearch" />

    <div class="common-table-container settings">
      <slot v-if="loadingInfo">
        <div class="getting-info">{{ $t('general.gettingInformation') }}</div>
      </slot>

      <!-- No data -->
      <div v-if="!loadingInfo && items.length === 0" class="no-data">{{ $t('general.noInformation') }}</div>

      <slot v-if="!loadingInfo && items.length > 0">
        <b-table :items="items" :fields="tableFields">
          <template v-slot:cell(name)="data">
            <span class="highlighted">{{ data.item.name }}</span>
          </template>

          <template v-slot:cell(is_member)="data">
            <b-form-checkbox v-model="data.item.is_assigned" @change="(e) => updateStatus(data.item.id, e)">
              <span>{{ $t('general.active') }}</span>
            </b-form-checkbox>
          </template>
        </b-table>
      </slot>
    </div>

    <pagination class="mt-4" v-show="!loadingInfo && items.length > 0" :totalItems="totalItems" @updateListHandler="getInfo"></pagination>
  </div>
</template>

<script>
import projectsSrv from '@/api/projects';
import pagination from '@/components/Pagination/Pagination.vue';
import Search from '@/components/Search/Search';

export default {
  props: {
    filters: Object,
  },

  components: {
    Search,
    pagination,
  },

  data() {
    return {
      projectId: this.$route.params.projectId,
      loadingInfo: false,
      totalItems: 0,
      tableFields: [
        { key: 'name', label: this.$t('general.name') },
        { key: 'is_member', label: this.$t('pages.settings.projects.members.isMember') },
      ],
      items: [],
      search: '',
    };
  },

  created() {
    this.getInfo();
  },

  methods: {
    onSearch(search) {
      this.search = search;

      this.getInfo();
    },

    async getInfo(currentPage = 1, itemsPerPage = 10) {
      this.loadingInfo = true;
      try {
        const searchData = {
          search: this.search,
          offset: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage,
        };
        this.loadingInfo = true;

        const { results, count } = await projectsSrv.getProjectTeams(this.projectId, searchData);
        this.items = results;
        this.totalItems = count;
      } catch (error) {}
      this.loadingInfo = false;
    },

    async updateStatus(teamId, val) {
      if (val) await projectsSrv.assignProjectTeam(this.projectId, teamId);
      else await projectsSrv.removeProjectTeam(this.projectId, teamId);
    },
  },
};
</script>
