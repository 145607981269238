import { localize } from 'vee-validate';
import i18n from '../i18n';

const getObjectFields = () => {
  const validations = i18n.t('forms.validations');
  const fields = {};

  Object.keys(validations).forEach((key) => {
    if (typeof validations[key] !== 'string') {
      fields[key] = validations[key];
    }
  });

  return fields;
};

function setLocaleValidation() {
  return localize(i18n.locale, {
    fields: getObjectFields(),
  });
}

export default setLocaleValidation;
