export default function guards(router) {
  router.beforeEach((to, from, next) => {
    const redirect = (redirects) => {
      for (let i = 0; i < redirects.length; i += 1) {
        // Temp: Agregar permisos luego
        // eslint-disable-next-line no-constant-condition
        if (true) return redirects[i].name;
      }

      return to.meta.fallback?.name || 'visual-vault';
    };

    const user = router.app.$store?.getters?.['auth/user'] || localStorage.vuex ? JSON.parse(localStorage.vuex).auth.user : null;
    const accessToken = user?.access_token;
    const authRequired = to.matched.some(route => route.meta.requiresAuth);

    document.querySelector('body').classList.remove('full-page');

    if (to.meta.redirects) {
      next({ name: redirect(to.meta.redirects) });
    }

    if (!authRequired) return accessToken && user.active && to.name === 'login' ? next({ name: 'visual-vault' }) : next();

    if (accessToken && user.active) return next();

    return next({ name: 'login' });
  });

  return router;
}
