<template>
  <div class="breadcrumb-wrapper">
    <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      items: [],
    };
  },

  computed: {
    ...mapGetters({
      breadcrumb: 'breadcrumb/currentItems',
    }),
  },
};
</script>
