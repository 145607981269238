<template>
  <multiselect-scroll
    v-model="model"
    :service="service"
    :required="required"
    :nullable="nullable"
    :disabled="disabled"
    :filter="filter"
    :single="single"
    :icon="icon"
    :label="label"
    :option-label="optionLabel"
    option-name="name"
    @input="onInput"
  />
</template>

<script>
import srv from '@/api/projects';
import MultiselectScroll from '@/components/Forms/Multiselect/MultiselectScroll.vue';

export default {
  components: {
    MultiselectScroll,
  },

  props: {
    value: { type: [Array, Object], default: () => [] },
    label: { type: String, default: 'Projects' },
    optionLabel: { type: String, default: 'Project' }, // if the name comes ''
    required: { type: Boolean, default: false },
    nullable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    filter: { type: Boolean, default: false },
    single: { type: Boolean, default: false },
    icon: { type: Boolean, default: false },
  },

  data() {
    return {
      model: this.single ? {} : [],
      service: srv.getProjects,
    };
  },

  watch: {
    value() {
      this.resetModel();
    },
  },

  methods: {
    resetModel() {
      this.model = this.single ? {} : [];
      if (this.value) this.model = JSON.parse(JSON.stringify(this.value));
    },

    onInput() {
      this.$emit('input', this.model);
    },
  },

  mounted() {
    this.resetModel();
  },
};
</script>
