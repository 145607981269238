<template>
  <div class="auth">
    <b-container>
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="4">

              <h1 class="title">
                <b-img :src="require('@/assets/img/logos/visualvault.svg')" alt="Visual Vault" />
              </h1>

              <div class="introduction">
                <p><span>{{$t('general.yourDigitalBrandbooks')}}</span></p>
                <p><strong>Visual Vault</strong>{{$t('general.descriptionText')}}</p>
              </div>

              <ValidationObserver ref="observer" v-slot="{ passes }">
                <b-form novalidate class="form-auth" autocomplete="off" @submit.prevent="passes(handleSubmit)">

                  <h2 class="welcome">{{$t('general.welcome')}}</h2>

                  <!-- Email -->
                  <ValidationProvider rules="required" name="email" v-slot="{ valid, errors }">
                    <b-form-group
                      :label="`${$t('forms.fields.email')} *`"
                      label-for="email"
                      :label-class="{ 'is-invalid': errors[0] }"
                      :invalid-feedback="errors[0]"
                    >
                      <b-form-input
                        :placeholder="$t('forms.placeholders.email')"
                        v-model="form.username"
                        :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    </b-form-group>
                  </ValidationProvider>

                  <!-- Password -->
                  <ValidationProvider rules="required" name="password" v-slot="{ valid, errors }">
                    <b-form-group
                      :label="`${$t('forms.fields.password')} *`"
                      label-for="password"
                      :label-class="{ 'is-invalid': errors[0] }"
                      :invalid-feedback="errors[0]"
                    >
                      <input-password
                        v-model="form.password"
                        :valid="valid"
                        :error="errors[0]"
                      ></input-password>
                    </b-form-group>
                  </ValidationProvider>

                  <b-button block type="submit" variant="primary" size="big" :disabled="form.sendingForm">{{ form.sendButtonText }}</b-button>

                </b-form>
              </ValidationObserver>
            </b-col>
            <b-col cols="8">
              <div class="bg">
                <b-img :src="require('@/assets/img/bg/login.png')" alt="Login" />
              </div>
            </b-col>
          </b-row>

        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
@import "../Auth";
</style>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { showError } from '@/components/ScreenAlert';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    InputPassword: () => import('@/components/Forms/InputPassword/InputPassword.vue'),
  },

  data() {
    return {
      form: {
        username: null,
        password: null,
        sendingForm: false,
        sendButtonText: this.$t('general.access'),
      },
    };
  },

  async created() {
    await this.$store.dispatch('auth/initAuth');
  },

  methods: {
    async resetForm() {
      this.form.sendButtonText = this.$t('general.access');
      this.form.sendingForm = false;
      await this.$store.dispatch('auth/logoutWithoutSession', false);
    },

    handleSubmit() {
      const { username, password } = this.form;
      this.form.sendButtonText = this.$t('general.processing');
      this.form.sendingForm = true;

      return this.$store.dispatch('auth/login', { username, password })
        .then(() => {
          this.$router.push({ name: 'visual-vault' }).catch(() => {});
        })
        .catch(({ message, error }) => {
          this.resetForm();

          showError({
            confirmLabel: '',
            title: this.$t('general.information'),
            content: message || error || this.$t('general.errorMessage'),
          });
        });
    },
  },

  watch: {
    '$i18n.locale': {
      handler() {
        this.form.sendButtonText = this.$t('general.access');
      },
    },
  },
};
</script>
