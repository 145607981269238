<template>
  <div class="dashboard-page">

    <!-- Filters -->
    <filters></filters>

    <!-- Search results -->
    <router-view></router-view>

  </div>
</template>

<script>
export default {
  components: {
    Filters: () => import('./Filters.vue'),
  },

  created() {
    this.setBaseBreadcrumb();
  },

  methods: {
    setBaseBreadcrumb() {
      this.setBreadcrumb([
        { text: 'Visual Vault', to: { name: 'home-dashboard' } },
        { text: 'Dashboard' },
      ]);
    },
  },
};
</script>
