<template>
  <b-modal :id="id" modal-class="common-modal confirm-modal" hide-footer>
    <template slot="modal-header">
      <h5 v-if="title" class="modal-title">{{ title }}</h5>
      <button v-if="showClose" @click="hideModal()" class="close"><i class="fas fa-times"></i></button>
    </template>

    <!-- Content -->
    <div class="content">
      <slot name="content">{{ content }}</slot>
    </div>

    <div class="form-actions" :class="{ center: showCancel }">
      <b-button v-if="showCancel" variant="bordered" @click="hideModal(false)">{{ cancelTitle }}</b-button>
      <b-button variant="primary" @click="hideModal(true)" :class="{ 'btn-block': !showCancel }">{{ okTitle }}</b-button>
    </div>
  </b-modal>
</template>

<style lang="scss" scoped>
@import './Confirm';
</style>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'confirm-modal',
    },
    title: {
      type: String,
      default: () => this.$t('components.confirm.title'),
    },
    content: {
      type: String,
      default: () => this.$t('components.confirm.defaultMessage'),
    },
    okTitle: {
      type: String,
      default: function okTitle() {
        return this.$t('general.accept');
      },
    },
    cancelTitle: {
      type: String,
      default: function cancelTitle() {
        return this.$t('general.cancel');
      },
    },
    showCancel: Boolean,
    close: Function,
    showClose: Boolean,
  },

  methods: {
    hideModal(response = false) {
      this.close(response);
    },
  },
};
</script>
