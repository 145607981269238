<template>
  <div class="sidebar">
    <!-- Menu -->
    <div class="menu">
      <ul v-if="true">
        <li>
          <b-link
            v-if="user.is_superuser || user.profile.permissions.includes('view_digital_creators')"
            :to="{ name: 'users-home' }" class="nav-users" :active="IsActive('users-home')"
            ><span>{{ $t('general.menu.users') }}</span></b-link
          >
        </li>
        <li class="action">
          <b-link
            v-if="user.is_superuser || user.profile.permissions.includes('create_digital_creators')"
            :to="{ name: 'user-creation' }"
            ><span>+ {{ $t('general.createNew') }}</span></b-link
          >
        </li>
      </ul>
      <ul v-if="true">
        <li>
          <b-link
            v-if="user.is_superuser || user.profile.permissions.includes('view_projects')"
            :to="{ name: 'projects-home' }" class="nav-projects" :active="IsActive('projects-home')"
            ><span>{{ $t('general.menu.projects') }}</span></b-link
          >
        </li>
        <li class="action">
          <b-link
            v-if="user.is_superuser || user.profile.permissions.includes('create_projects')"
            :to="{ name: 'project-creation' }"
            ><span>+ {{ $t('general.createNew') }}</span></b-link
          >
        </li>
      </ul>
      <ul v-if="true">
        <li>
          <b-link
            v-if="user.is_superuser || user.profile.permissions.includes('view_categories')"
            :to="{ name: 'categories-home' }" class="nav-categories" :active="IsActive('categories-home')"
            ><span>{{ $t('general.menu.categories') }}</span></b-link
          >
        </li>
        <li class="action">
          <b-link
          v-if="user.is_superuser || user.profile.permissions.includes('create_categories')"
            :to="{ name: 'category-creation' }"
            ><span>+ {{ $t('general.createNew') }}</span></b-link
          >
        </li>
      </ul>
      <ul v-if="true">
        <li>
          <b-link
            v-if="user.is_superuser || user.profile.permissions.includes('view_roles')"
            :to="{ name: 'profiles-home' }" class="nav-profiles" :active="IsActive('profiles-home')"
            ><span>{{ $t('general.menu.profiles') }}</span></b-link
          >
        </li>
        <li class="action">
          <b-link
            v-if="user.is_superuser || user.profile.permissions.includes('create_roles')"
            :to="{ name: 'profile-creation' }"
            ><span>+ {{ $t('general.createNew') }}</span></b-link
          >
        </li>
      </ul>
      <ul v-if="true">
        <li>
          <b-link
            v-if="user.is_superuser || user.profile.permissions.includes('view_teams')"
            :to="{ name: 'teams-home' }" class="nav-teams" :active="IsActive('teams-home')"
            ><span>{{ $t('general.menu.teams') }}</span></b-link
          >
        </li>
        <li class="action">
          <b-link
            v-if="user.is_superuser || user.profile.permissions.includes('create_teams')"
            :to="{ name: 'team-creation' }"
            ><span>+ {{ $t('general.createNew') }}</span></b-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import './Sidebar.scss';
</style>

<script>
import { mapGetters } from 'vuex';

export default {
  methods: {
    IsActive(page) {
      return this.$route.name === page;
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  mounted() {
    document.body.className = 'full-page';
  },

  beforeDestroy: () => {
    document.querySelector('body').classList.remove('full-page');
  },
};
</script>
