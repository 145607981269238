import api from './index';

function getFiles(data) {
  return new Promise((resolve, reject) => {
    api({
      path: '/files/files/',
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function createFiles(data) {
  return new Promise((resolve, reject) => {
    api({
      method: 'POST',
      path: '/files/files/',
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function UpdateStatusFile(fileGroupRecordId, data) {
  return new Promise((resolve, reject) => {
    api({
      method: 'PUT',
      path: `/files/files/${fileGroupRecordId}/status/`,
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function updateFileGroupRecord(fileGroupRecordId, data) {
  return new Promise((resolve, reject) => {
    api({
      method: 'PUT',
      path: `/files/files/${fileGroupRecordId}/`,
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function getFileGroupRecord(fileGroupRecordId) {
  return new Promise((resolve, reject) => {
    api({
      path: `/files/files/${fileGroupRecordId}/`,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

export default {
  getFiles,
  createFiles,
  UpdateStatusFile,
  getFileGroupRecord,
  updateFileGroupRecord,
};
