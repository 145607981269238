import api from './index';

function getProjects(data) {
  return new Promise((resolve, reject) => {
    api({
      path: '/projects/',
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function getProject(projectId) {
  return new Promise((resolve, reject) => {
    api({
      path: `/projects/${projectId}/`,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function createProject(data) {
  return new Promise((resolve, reject) => {
    api({
      method: 'POST',
      path: '/projects/',
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function updateProject(projectId, data) {
  return new Promise((resolve, reject) => {
    api({
      method: 'PUT',
      path: `/projects/${projectId}/`,
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function getProjectCreators(projectId, data) {
  return new Promise((resolve, reject) => {
    api({
      path: `/projects/${projectId}/digital-creators/`,
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function assignProjectCreator(projectId, creatorId) {
  return new Promise((resolve, reject) => {
    api({
      method: 'POST',
      path: `/projects/${projectId}/digital-creators/`,
      data: { digital_creator_id: creatorId },
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function removeProjectCreator(projectId, creatorId) {
  return new Promise((resolve, reject) => {
    api({
      method: 'DELETE',
      path: `/projects/${projectId}/digital-creators/`,
      data: { digital_creator_id: creatorId },
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function getProjectTeams(projectId, data) {
  return new Promise((resolve, reject) => {
    api({
      path: `/projects/${projectId}/teams/`,
      data,
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function assignProjectTeam(projectId, teamId) {
  return new Promise((resolve, reject) => {
    api({
      method: 'POST',
      path: `/projects/${projectId}/teams/`,
      data: { team_id: teamId },
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

function removeProjectTeam(projectId, teamId) {
  return new Promise((resolve, reject) => {
    api({
      method: 'DELETE',
      path: `/projects/${projectId}/teams/`,
      data: { team_id: teamId },
    })
      .then(res => resolve(res.data))
      .catch(error => reject(error.response.data));
  });
}

export default {
  getProjects,
  getProject,
  createProject,
  updateProject,

  getProjectCreators,
  assignProjectCreator,
  removeProjectCreator,

  getProjectTeams,
  assignProjectTeam,
  removeProjectTeam,
};
