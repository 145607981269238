import Categories from '@/pages/Settings/Categories/Categories.vue';
import CategoriesHome from '@/pages/Settings/Categories/Home/Home.vue';
import CategoryCreation from '@/pages/Settings/Categories/Creation/Creation.vue';
import CreateBaseInformation from '@/pages/Settings/Categories/Creation/BaseInfo/BaseInfo.vue';
import CategoryEdition from '@/pages/Settings/Categories/Edition/Edition.vue';
import EditionBaseInformation from '@/pages/Settings/Categories/Edition/BaseInfo/BaseInfo.vue';

export default {
  path: 'categories',
  component: Categories,
  children: [
    {
      path: '/',
      name: 'categories-home',
      component: CategoriesHome,
      meta: {
        permission: 'view_categories',
      },
    },
    {
      path: 'creation',
      name: 'category-creation',
      component: CategoryCreation,
      redirect: {
        name: 'category-base-info-creation',
      },
      children: [
        {
          path: 'base-info',
          name: 'category-base-info-creation',
          component: CreateBaseInformation,
          meta: {
            permissions: ['view_categories', 'create_categories'],
          },
        },
      ],
    },
    {
      path: ':categoryId/edition',
      name: 'category-edition',
      component: CategoryEdition,
      redirect: {
        name: 'category-base-info-edition',
      },
      children: [
        {
          path: 'base-info',
          name: 'category-base-info-edition',
          component: EditionBaseInformation,
          meta: {
            permissions: ['view_categories', 'edit_categories'],
          },
        },
      ],
    },
  ],
};
