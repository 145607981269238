<template>
  <div class="pagination-wrapper" :class="{ single: hideControls }">
    <div v-if="!hideControls" class="select-wrapper">
      <b-form-select v-model="perPage" @change="setResultsPerPage($event)">
        <option :value="1">{{ $t('general.pagination.showBy') }} 1</option>
        <option :value="5">{{ $t('general.pagination.showBy') }} 5</option>
        <option :value="10">{{ $t('general.pagination.showBy') }} 10</option>
        <option :value="20">{{ $t('general.pagination.showBy') }} 20</option>
        <option :value="50">{{ $t('general.pagination.showBy') }} 50</option>
        <option :value="100">{{ $t('general.pagination.showBy') }} 100</option>
      </b-form-select>
    </div>

    <!-- Pagination -->
    <b-pagination v-model="currentPage" hide-goto-end-buttons :total-rows="totalItems" :per-page="perPage"></b-pagination>

    <div v-if="!hideControls" class="set-page">
      <b-form @submit.prevent="setCurrentPage()">
        <b-input type="tel" :placeholder="$t('general.pagination.goToPage')" v-model="current" v-mask="'#########'"></b-input>
        <b-button @click="setCurrentPage()">{{ $t('general.search') }}</b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
  props: {
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    page: {
      type: Number,
      default: 1,
    },
    totalItems: Number,
    updateListHandler: Function,
    hideControls: {
      type: Boolean,
      default: false,
    },
  },

  directives: {
    mask,
  },

  data() {
    return {
      perPage: this.itemsPerPage,
      current: 1,
      currentPage: 1,
    };
  },

  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.perPage);
    },
  },

  methods: {
    setResultsPerPage(val) {
      this.perPage = val;
      if (this.currentPage === 1) {
        this.updateInfo();
      } else {
        this.currentPage = 1;
      }
    },

    setCurrentPage() {
      if (Number(this.current) >= this.totalPages) {
        this.currentPage = this.totalPages;
      } else if (!Number(this.current)) {
        this.currentPage = 1;
      } else {
        this.currentPage = this.current;
      }
    },

    updateInfo() {
      this.$emit('updateListHandler', this.currentPage, this.perPage);
    },
  },

  watch: {
    currentPage() {
      this.updateInfo();
      this.current = this.currentPage;
    },
  },
};
</script>
