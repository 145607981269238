<template>
  <div class="search-results">
    <slot v-if="loading">
      <div class="getting-info inside">{{ $t('general.gettingInformation') }}</div>
    </slot>

    <slot v-if="!loading">
      <!-- Results -->
      <div v-if="!items.length" class="no-data inside">{{ $t('general.noInformation') }}</div>

      <div v-else>
        <slot v-if="!loadingInfo && items.length > 0">
          <b-table responsive :fields="tableColumns" :items="items" class="common-table-container">
            <template v-slot:cell(actions)="data">
              <b-link
                v-if="user.is_superuser || user.profile.permissions.includes('review_file_groups')"
                @click="getFileGroupRecord(data.item.id)"
                >{{ $t('general.review') }}
              </b-link>
              <b-link
                v-else-if="
                  user.profile.permissions.includes('edit_file_groups') && !user.profile.permissions.includes('review_file_groups')
                "
                @click="getFileGroupRecord(data.item.id)"
                >{{ $t('general.edit') }}
              </b-link>
            </template>
            <template v-slot:cell(file)="data">
              <div class="img-wrapper">
                <img class="img" :src="data.item.preview_file" loading="lazy" />
              </div>
            </template>
            <template v-slot:cell(create_at)="data">
              <span v-if="data.item.create_at">
                {{ customFormatter(data.item.create_at) }}
              </span>
              <span v-if="!data.item.date">-</span>
            </template>
            <template v-slot:cell(tags)="data">
              <slot v-for="tag in data.item.tags">
                <label-tag small v-if="tag" :variant="data.item.tags.description">{{ tag.description }}</label-tag>
                <span :key="tag.id">&nbsp;</span>
              </slot>
            </template>
            <template v-slot:cell(status)="data">
              <label-tag small class="status" textColor="#fff" :class="data.item.status">{{ data.item.status }} </label-tag>
            </template>
            <template v-slot:cell(username)="data">
              <user-image :user="{ username: data.item.username, profile: { name: 'test' } }" :displayOnly="true">
                <template slot="info">
                  <strong>{{ data.item.username }}</strong>
                </template>
              </user-image>
            </template>
          </b-table>
          <!-- Upload files -->
          <files-review formType="edition" v-if="modal" :fileInfo="selectedFileInfo" @closeModal="closeModal"></files-review>
        </slot>
      </div>
    </slot>
    <!-- Pagination wrapper -->
    <pagination v-show="!loadingInfo && items.length > 0" :totalItems="totalItems" @updateListHandler="getFiles"></pagination>
  </div>
</template>

<style lang="scss">
@import './panel.scss';
</style>

<script>
import bus from '@/api/bus';
import commonSrv from '@/api/common';
import filesSrv from '@/api/files';
import LabelTag from '@/components/LabelTag/LabelTag.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import i18n from '@/i18n';
import { mapGetters } from 'vuex';

export default {
  components: {
    LabelTag,
    Pagination,
    FilesReview: () => import('@/components/Modals/Files/Review/Review.vue'),
    UserImage: () => import('@/components/UserImageOnly/UserImageOnly.vue'),
  },
  data() {
    return {
      files: [],
      categories: this.$store?.getters?.['common/categories'],
      user_id: this.$store?.getters?.['auth/user'].id,
      loading: false,
      results: [],
      totalItems: 0,
      filters: {
        project_id: this.$route.query.project_id || null,
        category_id: this.$route.query.category_id || null,
        search: this.$route.query.search || null,
      },
      selectedFileInfo: false,
      modal: false,
      tableColumns: [
        { key: 'file', label: i18n.t('general.file') },
        { key: 'status', label: i18n.t('general.status') },
        { key: 'username', label: i18n.t('general.publishedBy') },
        { key: 'name', label: i18n.t('general.fileName') },
        { key: 'create_at', label: i18n.t('general.publicationDate') },
        { key: 'project_name', label: i18n.t('general.project') },
        { key: 'category_name', label: i18n.t('general.category') },
        { key: 'tags', label: i18n.t('general.tags') },
        { key: 'actions', label: i18n.t('general.actions') },
      ],
      items: [],
      mexico: {
        user: null,
      },
      curPage: 1,
    };
  },

  computed: {
    isCategoryFull() {
      return this.$route.query.full;
    },
    hasAppliedFilters() {
      const filters = Object.values(this.$route.query).some(Boolean);
      if (filters === true) {
        this.getFiles();
      }
      return filters;
    },
    ...mapGetters({
      user: 'auth/user',
    }),
  },

  created() {
    bus.$on('getFiles', () => {
      this.getFiles(this.curPage);
    });
    this.getFiles();
  },
  methods: {
    getFiles(currentPage = 1, itemsPerPage = 10) {
      this.filters = this.$route.query;
      const searchData = {
        limit: itemsPerPage,
        offset: (currentPage - 1) * itemsPerPage,
        ...this.filters,
      };
      this.loading = true;
      this.loadingInfo = true;
      filesSrv
        .getFiles(searchData)
        .then((info) => {
          const { count, results } = info;
          this.items = results;
          this.totalItems = count;
          this.loadingInfo = false;

          this.curPage = currentPage;
        })
        .finally(() => {
          this.loading = false;
          this.loadingInfo = false;
        });
    },
    customFormatter(date) {
      return commonSrv.customDateFormat(date, 'DD/MM/YYYY');
    },
    openModal(fileGroupRecordInfo) {
      this.modal = true;
      this.selectedFileInfo = fileGroupRecordInfo;
      setTimeout(() => {
        this.$bvModal.show('review-files');
      }, 50);
    },
    closeModal(response) {
      this.modal = false;
      this.selectedEmployeeInfo = null;
      if (response) this.getFiles();
      this.$bvModal.hide('review-files');
    },
    getFileGroupRecord(fileGroupRecordId) {
      return filesSrv
        .getFileGroupRecord(fileGroupRecordId)
        .then((resp) => {
          this.openModal(resp);
        })
        .finally(() => {});
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getFiles();
      },
    },
  },
};
</script>
