import Teams from '@/pages/Settings/Teams/Teams.vue';
import TeamsHome from '@/pages/Settings/Teams/Home/Home.vue';
import TeamCreation from '@/pages/Settings/Teams/Creation/Creation.vue';
import CreateBaseInformation from '@/pages/Settings/Teams/Creation/BaseInfo/BaseInfo.vue';
import TeamEdition from '@/pages/Settings/Teams/Edition/Edition.vue';
import EditionBaseInformation from '@/pages/Settings/Teams/Edition/BaseInfo/BaseInfo.vue';
import MembersInformation from '@/pages/Settings/Teams/Members/MembersInfo.vue';

export default {
  path: 'teams',
  component: Teams,
  children: [
    {
      path: '/',
      name: 'teams-home',
      component: TeamsHome,
      meta: {
        permission: 'view-teams',
      },
    },
    {
      path: 'creation',
      name: 'team-creation',
      component: TeamCreation,
      redirect: {
        name: 'team-base-info-creation',
      },
      children: [
        {
          path: 'base-info',
          name: 'team-base-info-creation',
          component: CreateBaseInformation,
          meta: {
            permissions: ['view-teams', 'edit-teams'],
          },
        },
      ],
    },
    {
      path: ':teamId/edition',
      name: 'team-edition',
      component: TeamEdition,
      redirect: {
        name: 'team-base-info-edition',
      },
      children: [
        {
          path: 'base-info',
          name: 'team-base-info-edition',
          component: EditionBaseInformation,
          meta: {
            permissions: ['view-teams', 'edit-teams'],
          },
        },
        {
          path: 'members',
          name: 'team-members-edition',
          component: MembersInformation,
          meta: {
            permissions: ['view-teams', 'edit-teams'],
          },
        },
      ],
    },
  ],
};
