// The UI component rendered is @/components/ScreenAlert
import i18n from '@/i18n';
import bus from '@/api/bus';

export const defaultConfig = {
  type: 'info',
  title: '',
  text: '', // optional message  attached to the content (small gray text), text can´t overrides content
  content: '', // an HTML sometimes predefined as template (strong text), content overrides text
  icon: '',
  confirmLabel: '',
  cancelLabel: '',
  preventHide: false,
  onClose: () => {},
};

export const showServerError = (error, config = {}) => {
  const data = error.response ? error.response.data : {};
  bus.$emit('showAlert', {
    ...defaultConfig,
    type: 'error',
    icon: 'error',
    content: `
      <p>${data.message || error.message}</p>
      <strong>${i18n.t('components.screenAlert.errorMessage')}</strong>
    `,
    ...config,
  });
};

export const showError = (config = {}) => {
  bus.$emit('showAlert', {
    ...defaultConfig,
    type: 'error',
    icon: 'error',
    ...config,
  });
};

export const showSuccess = (config) => {
  bus.$emit('showAlert', {
    ...defaultConfig,
    type: 'success',
    icon: 'success',
    confirmLabel: i18n.t('general.continue'),
    content: `
      ${config?.text ? `<p>${config.text}</p>` : ''}
      <strong>${i18n.t('general.successfulOperation')}</strong>
    `,
    ...config,
  });
};

export const showConfirm = (config) => {
  bus.$emit('showAlert', {
    ...defaultConfig,
    confirmLabel: i18n.t('general.accept'),
    cancelLabel: i18n.t('general.cancel'),
    ...config,
  });
};

export const showAlert = config => bus.$emit('showAlert', { ...defaultConfig, ...config });

export const hideAlert = () => bus.$emit('hideAlert');

export default { showAlert };
