import Settings from '@/pages/Settings/Settings.vue';
import categories from './categories';
import profiles from './profiles';
import projects from './projects';
import teams from './teams';
import users from './users';

export default {
  path: '/settings',
  name: 'settings',
  component: Settings,
  meta: {
    wrapper: true,
    redirects: [
      { name: 'users-home', permission: 'view-users' },
      { name: 'profiles-home', permission: 'view-profiles' },
      { name: 'categories-home', permission: 'view-categories' },
      { name: 'teams-home', permission: 'view-teams' },

    ],
    // fallback: { name: 'login' },
  },
  children: [
    { ...users },
    { ...categories },
    { ...projects },
    { ...teams },
    { ...profiles },
  ],
};
