import CreateBaseInformation from '@/pages/Settings/Projects/Creation/BaseInfo/BaseInfo.vue';
import ProjectCreation from '@/pages/Settings/Projects/Creation/Creation.vue';
import EditionBaseInformation from '@/pages/Settings/Projects/Edition/BaseInfo/BaseInfo.vue';
import ProjectEdition from '@/pages/Settings/Projects/Edition/Edition.vue';
import ProjectsHome from '@/pages/Settings/Projects/Home/Home.vue';
import ProjectMembers from '@/pages/Settings/Projects/Members/Home.vue';
import Projects from '@/pages/Settings/Projects/Projects.vue';


export default {
  path: 'projects',
  component: Projects,
  children: [
    {
      path: '/',
      name: 'projects-home',
      component: ProjectsHome,
      meta: {
        permission: 'view-projects',
      },
    },
    {
      path: 'creation',
      name: 'project-creation',
      component: ProjectCreation,
      redirect: {
        name: 'project-base-info-creation',
      },
      children: [
        {
          path: 'base-info',
          name: 'project-base-info-creation',
          component: CreateBaseInformation,
          meta: {
            permissions: ['view-projects', 'edit-projects'],
          },
        },
      ],
    },
    {
      path: ':projectId/edition',
      name: 'project-edition',
      component: ProjectEdition,
      redirect: {
        name: 'project-base-info-edition',
      },
      children: [
        {
          path: 'base-info',
          name: 'project-base-info-edition',
          component: EditionBaseInformation,
          meta: {
            permissions: ['view-projects', 'edit-projects'],
          },
        },
      ],
    },
    {
      path: ':projectId/members',
      name: 'project-members',
      component: ProjectMembers,
      meta: {
        permissions: ['view-projects', 'share-projects'],
      },
    },
  ],
};
