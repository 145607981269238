import axios from 'axios';
import store from '@/store/index';

export default function setupInterceptors() {
  // Request
  axios.interceptors.request.use((response) => {
    const user = store?.getters?.['auth/user'];

    if (user) {
      const token = user.access_token ? `Bearer ${user.access_token}` : null || response.headers.customToken;

      delete response.headers.customToken;

      if (token) response.headers.Authorization = token;
    }

    return response;
  });

  // Response
  const responseInterceptor = axios.interceptors.response.use(
    response => response,
    async (error) => {
      /*
      * When response code is 401, try to refresh the token.
      * Eject the interceptor so it doesn't loop in case
      * token refresh causes the 401 response
      * See: https://stackoverflow.com/questions/51646853/automating-access-token-refreshing-via-interceptors-in-axios
      */
      const user = await store?.getters?.['auth/user'];

      axios.interceptors.response.eject(responseInterceptor);

      if (error.response.status === 401 || error.response.status === 403) {
        if (user?.registration_status !== 'complete' || !user) {
          store.dispatch('auth/logoutWithoutSession', !!user?.access_token || false);
        }

        if (user?.access_token && user?.active) {
          store.dispatch('auth/logout', true);
        }
      }

      return Promise.reject(error);
    },
  );
}
