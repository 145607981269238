<template>
  <div id="app">

    <!-- Base header -->
    <base-header></base-header>

    <div id="content">

      <!-- View -->
      <router-view></router-view>

    </div>

    <!-- Base Footer -->
    <base-footer></base-footer>

    <!-- Notification -->
    <screen-alert />

  </div>
</template>

<style lang="scss">
@import './styles/main.scss';
</style>

<script>
import { setRequestsHeader } from '@/api';
import setLocaleValidation from './validate/validations';

export default {
  components: {
    BaseHeader: () => import('@/components/BaseHeader/BaseHeader.vue'),
    BaseFooter: () => import('@/components/BaseFooter/BaseFooter.vue'),
    ScreenAlert: () => import('@/components/ScreenAlert/ScreenAlert.vue'),
  },

  methods: {
    init() {
      setLocaleValidation();
      this.getCommonCatalogs();
    },

    getCommonCatalogs() {
      return Promise.all([]);
    },

    showAlert(time) {
      return this.tokenLifetime <= time && this.tokenLifetime >= time - 8;
    },
  },

  watch: {
    '$i18n.locale': {
      immediate: true,
      async handler(locale) {
        document.querySelector('html').setAttribute('lang', locale);
        setRequestsHeader(locale);
        await this.$store.dispatch('i18n/changeLocale', locale);
        this.init();
      },
    },
  },
};
</script>
