<template>
  <div
    class="select-wrapper form-control multiselect-wrapper catalog-multiselect"
    :class="{
      search: icon,
      'align-placeholder': alignPH,
      'is-single': single,
      'is-filter': filter,
      'is-open': isOpen,
      'is-loading': loading,
      'is-disabled': disabled,
    }"
  >
    <vue-multiselect
      v-model="model"
      :options="compOptions"
      :multiple="!single"
      :disabled="isDisabled"
      :close-on-select="single"
      :clear-on-select="single"
      :showLabels="false"
      :allow-empty="allowEmpty"
      :label="label"
      :loading="loading"
      track-by="id"
      :placeholder="$t('components.multiselect.selectOrType')"
      :searchable="searchable"
      :internalSearch="internalSearch"
      @search-change="onSearchChange"
      @input="onInput"
      @open="onOpen"
      @close="onClose"
    >
      <template slot="clear">
        <b-button v-if="allowSingleClear" class="single-clear" variant="clean" title="Borrar" @click="clearAll">
          <b-img :src="require('@/assets/img/icons/delete-document.svg')" />
        </b-button>
      </template>

      <template slot="beforeList">
        <div v-if="!single" class="counters">
          <ul>
            <li :class="{ disabled: isEmpty }">
              <b-link @click="clearAll">{{ $t('components.multiselect.none') }}</b-link>
            </li>
            <li :class="{ disabled: isFull }">
              <b-link @click="selectAll">{{ $t('components.multiselect.all') }}</b-link>
            </li>
            <li class="selected">{{ isEmpty ? `(${totalOptions})` : `${model.length} / ${totalOptions}` }}</li>
          </ul>
        </div>
      </template>

      <template slot="afterList">
        <div v-observe-visibility="onScrollEnd" class="loading-msg">{{ loading ? 'Cargando...' : '' }}</div>
      </template>

      <template slot="selection" slot-scope="{ values, search, isOpen }">
        <!-- Filter mode placeholder -->
        <span v-if="filter && !values.length" class="multiselect__single">
          {{ placeholder }}
        </span>
        <!-- Multiselect with selected items -->
        <span v-if="!single && values.length" v-show="!isOpen" :title="selectedLabel(values, true)" class="multiselect__single">
          {{ selectedLabel(values) }}
        </span>
        <!-- Multiselect no selected -->
        <span v-if="!single && !filter && !values.length" v-show="!isOpen" class="multiselect__single">
          {{ $t('components.multiselect.select') }}
        </span>
        <!-- Single selection -->
        <span v-if="single" v-show="!isOpen" class="multiselect__single selected-name">
          {{ model.id ? model[label] : filter ? placeholder : $t('components.multiselect.noSelected') }}
        </span>
      </template>

      <template slot="noResult">
        <span class="no-results">{{ $t('general.noResults') }}</span>
      </template>

      <template slot="noOptions">
        <span class="no-results">{{ $t('general.noInformation') }}</span>
      </template>
    </vue-multiselect>
  </div>
</template>

<style lang="scss">
@import './Multiselect.scss';
</style>

<script>
import VueMultiselect from 'vue-multiselect';
import { ObserveVisibility } from 'vue-observe-visibility';

export default {
  components: {
    VueMultiselect,
  },

  directives: {
    'observe-visibility': ObserveVisibility,
  },

  props: {
    value: { type: [Array, Object], default: () => [] },
    options: { type: Array, default: () => [] },
    icon: { type: Boolean, default: false },
    single: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    // allow "select option" on required instead force first selection
    nullable: { type: [Boolean, String], default: false },
    disabled: { type: Boolean, default: false },
    filter: { type: Boolean, default: false },
    label: { type: String, default: 'name' },
    labelEmpty: { type: String, default: 'Item' },
    placeholder: { type: String, default: '' },
    total: { type: Number, default: 0 },
    loading: { type: Boolean, default: false },
    searchable: { type: Boolean, default: true },
    internalSearch: { type: Boolean, default: true }, // true:unique list | false:ajax scroll list
  },

  data() {
    return {
      isOpen: false,
      search: '',
      model: this.single ? {} : [],
      timer: 0,
    };
  },

  computed: {
    alignPH() {
      if (this.single && Object.keys(this.model).length) return true;
      if (!this.single && this.model.length) return true;
      return !this.filter;
    },

    compOptions() {
      const options = this.options.map((item) => {
        if (!(item[this.label] || '').trim() && item.id) item[this.label] = `${this.labelEmpty} (${item.id})`;
        return item;
      });
      return options;
    },

    totalOptions() {
      return this.total || this.options.length;
    },

    allowEmpty() {
      return !this.required || this.nullable;
    },

    isEmpty() {
      return this.single ? 0 : !this.model.length;
    },

    isFull() {
      if (this.single) return 0;
      if (this.total) return this.model.length === this.total;
      return this.model.length === this.options.length;
    },

    isDisabled() {
      return this.disabled || !this.totalOptions;
    },

    allowSingleClear() {
      return this.single && !this.disabled && !this.required && this.model?.id;
    },
  },

  watch: {
    value() {
      this.reset();
    },
    options() {
      this.reset();
    },
  },

  methods: {
    reset(data = []) {
      this.model = JSON.parse(JSON.stringify(this.value || data));

      // force initialization on required fields
      let hasValue = this.single ? this.model?.id : this.model.length;

      // When the options changes, validate the previous selections exists in the new options
      if (this.single) {
        if (!this.options.find((item) => item.id === hasValue)) hasValue = false;
      } else {
        const ids = this.options.map((item) => item.id);
        this.model = this.model.filter((item) => ids.includes(item.id));
        hasValue = this.model.length;
      }

      if (!this.search && this.required && !this.nullable && this.totalOptions && !hasValue) {
        if (this.single) this.model = { ...this.options[0] };
        else this.model.push(this.options[0]);
        this.onInput();
      }
    },

    selectedLabel(items, mouseover = false) {
      const len = mouseover ? 5 : 3;
      const plus = mouseover && items.length > len ? `\n... ${items.length - len}+` : '';
      return `${items
        .slice(0, len)
        .map((item) => item[this.label])
        .join(mouseover ? '\n' : ', ')}${plus}`;
    },

    onSearchChange(search) {
      this.search = search;
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => this.$emit('search-change', search), 900);
    },

    clearAll() {
      const items = this.single ? null : [];
      if (this.required && this.single) return;
      // force to select one on required field
      if (this.required && !this.nullable && !this.single && this.model.length) items.push(this.model.at(0));
      this.$emit('clear-all');
      this.$emit('input', items);
    },

    selectAll(emitToParent = true) {
      if (emitToParent) this.$emit('select-all');
      this.$emit('input', this.options);
    },

    onInput() {
      this.$emit('input', this.model);
    },

    onOpen() {
      this.isOpen = true;
    },

    onClose() {
      this.isOpen = false;
    },

    onScrollEnd() {
      this.$emit('scroll-end');
    },
  },

  mounted() {
    this.reset();
  },
};
</script>
