<template>
  <div class="common-page teams-home">
    <layout-wrapper>
      <template #sidebar>
        <sidebar></sidebar>
      </template>

      <!-- Breadcrumb -->
      <breadcrumb></breadcrumb>

      <slot v-if="loadingInfo">
        <div class="getting-info">{{ $t('general.gettingInformation') }}</div>
      </slot>

      <slot v-if="!loadingInfo">
        <b-alert :show="deleteResponse !== null" variant="danger">
          {{ deleteResponse }}
        </b-alert>

        <!-- Page title -->
        <page-title variant="team">
          <template #title>{{ $t('pages.settings.teams.title') }}</template>
          <template #actions>
            <b-button
              v-if="user.is_superuser || user.profile.permissions.includes('create_teams')"
              class="add"
              variant="primary"
              @click="createTeam"
              ><span>+ {{ $t('pages.settings.teams.createTeam') }}</span>
            </b-button>
          </template>
        </page-title>

        <!-- No data -->
        <div v-if="items.length === 0" class="no-data">{{ $t('general.noInformation') }}</div>

        <!-- Topics -->
        <div v-if="items.length > 0" class="topics mb-4">
          <Search class="mb-4" :instant="false" :placeholder="$t('pages.settings.teams.search')" @onSubmit="onSearch" />

          <div class="common-table-container settings">
            <b-table :fields="fields" :items="items">
              <template #head(actions)="data">
                <div class="center">{{ data.label }}</div>
              </template>
              <template #cell(teamName)="data">
                <div class="name">
                  <strong>{{ data.item.name }}</strong>
                </div>
              </template>
              <template #cell(teamDescription)="data">
                <div class="description">
                  <strong>{{ data.item.description }}</strong>
                </div>
              </template>
              <template #cell(actions)="data">
                <div class="actions">
                  <b-link v-if="user.is_superuser || user.profile.permissions.includes('edit_teams')" @click="onEdit(data.item.id)">
                    <i class="fa fa-pen"></i>
                    <span>{{ $t('general.edit') }}</span>
                  </b-link>
                  <b-link @click="onViewMembers(data.item.id)">
                    <i class="fa fa-users"></i>
                    <span>{{ $t('pages.settings.teams.members') }}</span>
                  </b-link>
                  <!-- <b-link @click="onDeleteConfirm(data.item.id)">
                      <i class="fa fa-trash"></i>
                      <span>{{ $t('general.eliminate') }}</span>
                    </b-link> -->
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </slot>

      <!-- Confirm modal -->
      <confirm
        :title="$t('components.confirm.title')"
        :content="$t('pages.settings.teams.onDeleteMessage')"
        showCancel
        :close="confirmAction"
      ></confirm>

      <!-- Pagination wrapper -->
      <pagination
        v-show="!loadingInfo && items.length > 0"
        :page="0"
        :totalItems="totalItems"
        @updateListHandler="getTeamsInfo"
      ></pagination>
    </layout-wrapper>
  </div>
</template>

  <style lang="scss" scoped>
@import './Home';
</style>

<script>
import teamsSrv from '@/api/teams';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';
import LayoutWrapper from '@/components/Layout/Layout.vue';
import Confirm from '@/components/Modals/Confirm/Confirm.vue';
import PageTitle from '@/components/PageTitle/PageTitle.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import Search from '@/components/Search/Search';
import Sidebar from '@/components/Sidebar/Settings/Sidebar.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    LayoutWrapper,
    Sidebar,
    Breadcrumb,
    PageTitle,
    Pagination,
    Confirm,
    Search,
  },

  data() {
    return {
      loadingInfo: false,
      deleteResponse: null,
      currentTeamId: null,
      search: '',
      totalItems: 0,
      items: [],
      fields: [
        { key: 'teamName', label: this.$t('general.team') },
        { key: 'teamDescription', label: this.$t('general.description') },
        { key: 'actions', label: this.$t('general.actions') },
      ],
    };
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },

  methods: {
    onSearch(search) {
      this.search = search;

      this.getTeamsInfo();
    },

    setTopic(collection, id) {
      return (this[collection] || []).find((e) => e.id === id)?.description || '-';
    },

    getCatalogueItemNames(collection, currentIds) {
      return this[collection]
        .filter((item) => currentIds.includes(item.id))
        .map((item) => item.name)
        .join(',');
    },

    onDeleteConfirm(teamId) {
      this.currentTeamId = teamId;
      this.$bvModal.show('confirm-modal');
    },

    confirmAction(response) {
      this.$bvModal.hide('confirm-modal');
      if (response) {
        this.deleteTeam(this.currentTeamId);
      }
    },

    createTeam() {
      this.$router.push({ name: 'team-creation' });
    },

    onEdit(teamId) {
      this.$router.push({ name: 'team-edition', params: { teamId } });
    },
    onViewMembers(teamId) {
      this.$router.push({ name: 'team-members-edition', params: { teamId } });
    },

    async deleteTeam(teamId) {
      try {
        const res = await teamsSrv.deleteTeam(teamId);
        this.deleteResponse = res.message;
        this.getTeamsInfo();
      } catch (error) {
        this.deleteResponse = error.message;
      }
    },

    async getTeamsInfo(currentPage = 1, itemsPerPage = 10) {
      const searchData = {
        search: this.search,
        offset: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
      };

      this.loadingInfo = true;

      try {
        const { results, count } = await teamsSrv.getTeams(searchData);
        this.items = results;
        this.totalItems = count;
      } catch (error) {}
      this.loadingInfo = false;
    },

    async init() {
      this.getTeamsInfo();
    },
  },

  created() {
    this.init();

    this.setBreadcrumb([
      { text: 'Visual Vault', to: { name: 'visual-vault' } },
      { text: this.$t('general.settings') },
      { text: this.$t('pages.settings.teams.title') },
    ]);
  },
};
</script>
