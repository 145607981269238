<template>
  <div class="search-results">
    <slot v-if="loading">
      <div class="getting-info inside">{{ $t('general.gettingInformation') }}</div>
    </slot>

    <slot v-if="!loading">
      <!-- Results -->
      <div v-if="!results.length" class="no-data inside">{{ $t('general.noInformation') }}</div>

      <div v-else class="results">
        <slot v-if="isCategoryFull">
          <!-- Category full -->
          <category-full v-for="({ category, list }, i) in results" :key="i" :category="category" :list="list"></category-full>
        </slot>
        <slot v-else>
          <!-- Category resume -->
          <category-resume
            v-for="({ category, category_id, list }, i) in results"
            :key="i"
            :category="category"
            :category_id="category_id"
            :list="list"
          ></category-resume>
        </slot>
      </div>
    </slot>
  </div>
</template>

<script>
import filesSrv from '@/api/files';
import { mapGetters } from 'vuex';

export default {
  components: {
    CategoryResume: () => import('@/components/Results/CategoryResume/CategoryResume.vue'),
    CategoryFull: () => import('@/components/Results/CategoryFull/CategoryFull.vue'),
  },
  data() {
    return {
      categoriesDescription: {},
      files: [],
      loading: false,
      results: [],
    };
  },

  computed: {
    ...mapGetters({
      categories: 'common/categories',
    }),
    isCategoryFull() {
      return this.$route.query.full;
    },
    hasAppliedFilters() {
      return Object.values(this.$route.query).some(Boolean);
    },
  },

  methods: {
    search() {
      this.loading = true;
      this.results = [
        {
          category: 'Logotipo',
          list: this.files,
        },
      ];
    },
    getFiles() {
      this.files = [];
      this.results = [];
      this.loading = true;
      const filter = this.$route.query;
      filter.status = 'published';
      filesSrv
        .getFiles(filter)
        .then((info) => {
          const { results } = info;
          this.files = results;
          this.categories.forEach((element) => {
            const result = {
              category: element.name,
              category_id: element.id,
              list: this.files.filter((item) => item.category_id === element.id),
            };
            if (result.list.length > 0) {
              this.results.push(result);
            }
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getFiles();
      },
    },
  },
};
</script>
